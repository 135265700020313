import { 
    BASE_URL,
    UPLOAD_URL
 } from "../../../service/ApiUrls";
import { post, get, Delete, upload } from "../../../service";

const FACILITIES_PATH = 'facility'

const HOSPITAL_FACILITIES_PATH = 'hospital-facility'

const getFacilitiesApiUrl = () => {
    return `${BASE_URL}${FACILITIES_PATH}/active`;
};

const getHospitalFacilitiesApiUrl = (id) => {
    if (id) {
        return `${BASE_URL}${HOSPITAL_FACILITIES_PATH}/hospital/${id}` 
    } 
    return `${BASE_URL}${HOSPITAL_FACILITIES_PATH}`; 
};


export const deleteItem = async (data) => {
    const response = await Delete(getHospitalFacilitiesApiUrl(), data);
    return response;
};

export const uploadFile = async (data) => {
    const response = await upload(UPLOAD_URL, data);
    return response;
};

export const getFacilities = async () => {
    const response = await get(getFacilitiesApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const saveHospitalFacility = async (data) => {
    const response = await post(getHospitalFacilitiesApiUrl(), data);
    return response;
};

export const getHospitalFacilities = async (id) => {
    if(!id) {
        return []
    }
    const response = await get(getHospitalFacilitiesApiUrl(id));
    if(!response) {
        return []
    }
    return response;
};




