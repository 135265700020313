import React from "react";
import useIndex from "./useIndex";
import Table from "../../../components/containers/table";
import Card from "../../../components/containers/card";
import Loader from "../../../components/general/loader";
import Popover from "../../../components/containers/popover";
import Toast from "../../../components/general/toast";
import NoDataMessage from "../../../components/general/NoDataMessage";
import { FormProvider } from "react-hook-form";
import { Input } from "../../../components/inputs/input";
import Select from "../../../components/inputs/select";
import { nameField } from "../../../utils/inputValidations";
import ProcessLoader from "../../../components/general/process-loader";

export default function HospitalServices({hospitalId}) {
    const {
        list, 
        handleSubmit, 
        loading, 
        handleAddClick, 
        handleCancelClick,
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        handleRowClick,
        message,
        selectedItem,
        columns,
        levels,
        handleTabClick ,
        methods,
        apiInProgress,
        services
    } = useIndex(hospitalId)

    const priceField = nameField("price", "price", "Price *", "Enter price", true, 0, 10)

    
    return(
        <Card onAdd={handleAddClick} showAddButton>
            <ul className="nav nav-tabs nav-bordered mb-3" role="tablist">
                {levels && levels.map && levels.map((item, index) => 
                <li key={`level-link${item.id}`} className="nav-item" role="presentation" onClick={() => handleTabClick(item)}>
                    <a href={`#level${item.id}`} data-bs-toggle="tab" aria-expanded="false" className={`nav-link${index === 0 ? ' active' : ''}`} aria-selected="false" role="tab" tabIndex="-1">
                        {item.name}
                    </a>
                </li>
                )}
            </ul>
            <FormProvider {...methods}>
                <form
                onSubmit={e => e.preventDefault()}
                noValidate
                autoComplete="off"
                className="container">
                    <div className="row">
                        <div className='col-md-3'>
                            <Select defaultValue={selectedItem && selectedItem.service.id} name="serviceId" id="serviceId" label="Select service *" list={services} valueProperty="id" />
                        </div>
                        <div className='col-md-3'>
                            <Input name="price" id="price" type="number" {...priceField} defaultValue={selectedItem && selectedItem.price} />
                        </div>
                        <div className='col-md-3 inline-form-btn-container'>
                            <button className="btn btn-md btn-info waves-effect waves-light pull-right ml-3" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </form>
            </FormProvider>
            
            {loading ? <Loader /> : <Table data={list} className="dataTable" columns={columns} pagination />}
            {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.service.name}'`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} '${selectedItem.service.name}'?`}</p>
                </Popover>
            }
            {apiInProgress && <ProcessLoader />}
            {showMessage && <Toast text={message} className="success" /> }
        </Card>
    )
}