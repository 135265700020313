import React from "react";
import { FormProvider } from 'react-hook-form'
import { nameField } from "../../../utils/inputValidations";
import { Input } from "../../../components/inputs/input";
import PagePopup from "../../../components/containers/page-popup";
import Select from "../../../components/inputs/select";
import useTimingForm from "./useTimingForm";
import Table from "../../../components/containers/table";
import Popover from "../../../components/containers/popover";
import Toast from "../../../components/general/toast";
import Loader from "../../../components/general/loader";


export default function Form({hospitalId, doctor, onCancel}) {

    const itemName = "Hospital Doctor"
    const startTimeField = nameField("startTime", "startTime", "Start time *", "Select start time", true, 0, 10, "Required", "time")
    const endTimeField = nameField("endTime", "endTime", "End time *", "Select end time", true, 0, 10, "Required", "time")

    const {
        handleSubmit, 
        handleCancel, 
        days, 
        methods,
        list,
        handleRowClick,
        selectedItem,
        columns,
        loading,
        showConfirmation,
        onDeleteCancel,
        onDeleteConfirm,
        message,
        showMessage
    } = useTimingForm(hospitalId, doctor.id);

    return (
        <PagePopup className="size-lg" title={doctor.firstName + " " + doctor.lastName +" timings"} onBack={onCancel} showHeader showBackNavigation >
            <FormProvider {...methods}>
            <form
            onSubmit={e => e.preventDefault()}
            noValidate
            autoComplete="off"
            className="container">
                <div className="row">
                    <div className='col-md-3'>
                        <Select name="dayId" id="dayId" label="Select day" list={days} valueProperty="id" />
                    </div>
                    <div className='col-md-3'>
                        <Input {...startTimeField}/>
                    </div>
                    <div className='col-md-3'>
                        <Input {...endTimeField}/>
                    </div>
                    <div className='col-md-3 inline-form-btn-container'>
                        <button className="btn btn-md btn-info waves-effect waves-light pull-right ml-3" onClick={handleSubmit}>Save</button>
                        <button className="btn btn-md btn-danger pull-right ml-3" onClick={handleCancel}>Cancel</button>
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-3'></div>
                </div>
            </form>
        </FormProvider>
        {loading ? <Loader /> : <Table data={list} columns={columns} onRowClicked={handleRowClick} />}
        {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.day.name}' - ${selectedItem.startTime} to ${selectedItem.endTime}`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} ''${selectedItem.day.name}' - ${selectedItem.startTime} to ${selectedItem.endTime}'?`}</p>
                </Popover>
                }
        {showMessage && <Toast text={message} className="success" /> }
        </PagePopup>
    )
}