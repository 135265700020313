import { useState } from 'react'
import { createHospitalType, updateHospitalType } from './HospitalTypeService';

export default function useHospitalTypeForm(onSave) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const handleSubmit = async (data, saveAndAddAnother) => {
        setApiInProgress(true)
        let payload = {id: data.id, name: data.name, description: data.description}
        let response;
        if(payload.id) {
            response = await updateHospitalType(payload)
        }else {
            response = await createHospitalType(payload)
        }
        if(!response.success) {
            setMessage(response.data.errors.message)
            setShowMessage(true)
        }else {
            setMessage("")
            setShowMessage(false)
            if(onSave) {
                onSave(data, saveAndAddAnother)
            }
        }
        setApiInProgress(false)
    }

    return {handleSubmit, apiInProgress, showMessage, message}
}