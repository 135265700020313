import React, { useEffect } from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../../utils/inputValidations";
import { Input } from "../../../components/inputs/input";
import PagePopup from "../../../components/containers/page-popup";
import useHospitalTypeForm from './useHospitalTypeForm'
import ProcessLoader from "../../../components/general/process-loader";
import FormActionButtons from "../../../components/inputs/form-action-buttons";


export default function HospitalTypeForm({formData, onCancel, onSave}) {

    const nameInputField = nameField("name", "name", "Hospital Type", "Enter hospital type", true, 3, 100)
    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const {handleSubmit, apiInProgress, showMessage, message} = useHospitalTypeForm(onSave);

    const methods = useForm(
        {
            defaultValues: formData ? {
            id: formData.id,
            name: formData.name,
            description: formData.description,
            deleted: formData.deleted
            }: {}
        }
    )


    const onSubmit = methods.handleSubmit((data, event) => {

        console.log(data)
        console.log(event.target.id)

        handleSubmit(data, event.target.id === "saveAndAddAnotherBtn")
        methods.reset()
    })

    return (
        <PagePopup title="Hospital Type" onBack={onCancel} showHeader showBackNavigation>
            <FormProvider {...methods}>
                <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                    <Input id="name" {...nameInputField} className="mb-3" showMessage={showMessage} message={message} required/>
                    <Input type="textarea" id="description" {...descriptionInputField} className="mb-3" />
                    <FormActionButtons onSubmit={onSubmit} onCancel={onCancel} />
                    {apiInProgress && <ProcessLoader />}
                </form>
            </FormProvider>
        </PagePopup>
    )
}