import React from "react";
import Card from "../../components/containers/card";
import FormHeader from "../../components/general/form-header";
import useAttachments from "./useAttachments";
import Popover from "../../components/containers/popover";
import Toast from "../../components/general/toast";
import FileSelector from "../../components/inputs/file-selector";

export default function Photos({hospitalId, existingFiles}) {

    const {
        showMessage, 
        message,
        handleDeleteClick,
        files,
        onFileSelect,
        showConfirmation,
        onDeleteCancel,
        onDeleteConfirm,
        selectedItem,
        uploadInProgress,
        handleSubmit

    } = useAttachments(hospitalId, existingFiles)

    return(
        <Card>
            <FormHeader title="Photos" />
                <div className="row">
                    <FileSelector onDelete={handleDeleteClick} label="Select photos" existingFiles={files} uploadInProgress={uploadInProgress} onFileSelect={onFileSelect} multiple />
                </div>
                {files && files.length > 0 && 
                <div className="p-2 bt-1">
                    <div className="col-md-12">
                        <button className="btn btn-md btn-info waves-effect waves-light pull-right" onClick={handleSubmit}>Upload</button>
                    </div>
                </div>
                }
                {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.contactNumber}'`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to delete the photo?`}</p>
                </Popover>
            }
            
            {showMessage && <Toast text={message} className="success" /> }
        </Card>
    )




}