import React from "react";
import { AnimatePresence, motion } from 'framer-motion'
import { MdError } from 'react-icons/md'
import { findInputError, isFormInvalid } from '../../utils'
import { useFormContext } from 'react-hook-form'
export default function Select({ id, className, enabled = true, label, name, type, placeHolder, list, valueProperty, labelProperty = "name", onChange, defaultValue, required = true}) {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const inputErrors = findInputError(errors, name)
  const isInvalid = isFormInvalid(inputErrors)
  const handleInputChange = (event) => {
    if(onChange) {
      onChange(event)
    }
  }
    return (
        <div className={className ? className : ""} >
            <div className="flex justify-between">
                <label htmlFor={id} className="form-label">
                {label}
                </label>
                <AnimatePresence mode="wait" initial={false}>
                {isInvalid && (
                    <InputError
                    message="Required"
                    key="Required"
                    />
                )}
                </AnimatePresence>
            </div>
            <select {...register(name, {required: required, onChange: handleInputChange})} defaultValue={defaultValue} className="form-control" disabled={!enabled} name={name} type={type} placeholder={placeHolder}>
                <option value="">Select</option>
                {list && list.map && list.map((item) =>  
                    <Option selected={item.id === defaultValue} key={item[valueProperty]} value={item[valueProperty]} label={item[labelProperty]} />
                )}
            </select>
        </div>
    );
}

 function Option({selected, value, label}) {
  return (
    <>
    {selected ?
        <option selected value={value}>{label}</option>
        : <option value={value}>{label}</option>
    }
    </>
  )
 }

const InputError = ({ message }) => {
    return (
      <motion.p
        className="error-message"
        {...framer_error}
      >
        <MdError />
        {message}
      </motion.p>
    )
  }
  
  const framer_error = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
    transition: { duration: 0.2 },
  }
