import React from 'react'
import HGrow from '../elements/h-grow'
import VDiv from '../containers/vdiv'
import HDiv from '../containers/hdiv'
import PageSubTitle from '../elements/texts/page-sub-title'
import PageTitle from '../elements/texts/page-title'
import CloseButton from '../inputs/close-button'

export default function PagePopupHeader({onBack, title, subTitle, children}) {
    return (
        <HDiv className="popup-header">
            <VDiv>
                {title ? <PageTitle text={title}  /> : null}
                {subTitle ? <PageSubTitle text={subTitle}/> : null}
            </VDiv>
            <HGrow />
            {children ? children : null}
            <CloseButton onClick={onBack} />
        </HDiv>
    )
}