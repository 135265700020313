import React from "react";

export default function PageTitle({title, breadCrumbItems, showSaveButton, onSubmit}) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        {breadCrumbItems && 
                        <ol className="breadcrumb m-0">
                            {breadCrumbItems.map((item, index) => 
                                <li key={index} className="breadcrumb-item">{item}</li>
                            )}
                        {showSaveButton &&
                        <li>
                            <button className="btn btn-md btn-info waves-effect waves-light pull-right ml-3" onClick={onSubmit}>Save</button>
                        </li>
                        }
                        </ol>
                        }

                    </div>
                    <h4 className="page-title">{title}</h4>
                </div>
            </div>
        </div>
    )
}