import { 
    BASE_URL,
    UPLOAD_URL
 } from "../../../service/ApiUrls";
import { post, get, Delete, upload } from "../../../service";

const DOCTORS_PATH = 'doctor'

const HOSPITAL_DOCTORS_PATH = 'hospital-doctor'

const HOSPITAL_DOCTOR_TIME_PATH = 'hospital-doctor-time'

const DAYS_PATH = 'day'

const getDoctorsApiUrl = () => {
    return `${BASE_URL}${DOCTORS_PATH}/active`;
};

const getHospitalDoctorsApiUrl = (id) => {
    if (id) {
        return `${BASE_URL}${HOSPITAL_DOCTORS_PATH}/hospital/${id}` 
    } 
    return `${BASE_URL}${HOSPITAL_DOCTORS_PATH}`; 
};

const getHospitalDoctorTimingsApiUrl = (hospitalId, doctorId) => {
    if (hospitalId) {
        return `${BASE_URL}${HOSPITAL_DOCTOR_TIME_PATH}/hospital/${hospitalId}/doctor/${doctorId}` 
    } 
    return `${BASE_URL}${HOSPITAL_DOCTOR_TIME_PATH}`; 
};

const getDaysApiUrl = () => {
    return `${BASE_URL}${DAYS_PATH}`;
};


export const deleteItem = async (data) => {
    const response = await Delete(getHospitalDoctorsApiUrl(), data);
    return response;
};

export const uploadFile = async (data) => {
    const response = await upload(UPLOAD_URL, data);
    return response;
};

export const getDoctors = async () => {
    const response = await get(getDoctorsApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const saveHospitalDoctor = async (data) => {
    const response = await post(getHospitalDoctorsApiUrl(), data);
    return response;
};

export const getHospitalDoctors = async (id) => {
    if(!id) {
        return []
    }
    const response = await get(getHospitalDoctorsApiUrl(id));
    if(!response) {
        return []
    }
    return response;
};

export const getDays = async () => {
    const response = await get(getDaysApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const saveHospitalDoctorTime = async (data) => {
    const response = await post(getHospitalDoctorTimingsApiUrl(), data);
    return response;
};

export const getHospitalDoctorTimings = async (hospitalId, doctorId) => {
    if(!hospitalId) {
        return []
    }
    const response = await get(getHospitalDoctorTimingsApiUrl(hospitalId, doctorId));
    if(!response) {
        return []
    }
    return response;
};




