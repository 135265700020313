import { useEffect, useState } from 'react'
import { getStates } from '../../components/address/rest-service'; 
import { searchDoctor } from './rest-service';
import { useNavigate } from 'react-router-dom';

export default function useSearchForm() {

    const navigate = useNavigate();

    const [apiInProgress, setApiInProgress] = useState(false)

    const [states, setStates] = useState([])

    const pageTitle = "Search Doctor"

    const breadcrumbItems = ["Home", "Doctors", "Search"]


    const handleSubmit = async (payload) => {
        console.log(payload)
        //setApiInProgress(true)
        const response = await searchDoctor(payload.registrationNumber, payload.stateId);
        if(response && response.id) {
            navigate("/doctor/"+response.id)
        }else {
            navigate("/doctor/"+payload.registrationNumber+"/state/"+payload.stateId)

        }
    }

    const fetchStates = async () => {
        const response = await getStates()
        setStates(response)
    }


    useEffect(() => {
        fetchStates()
    }, [])

    return {
        handleSubmit, 
        apiInProgress, 
        pageTitle,
        breadcrumbItems,
        states
    }
}