import React from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../../utils/inputValidations";
import { Input } from "../../../components/inputs/input";
import Select from "../../../components/inputs/select";
import PagePopup from "../../../components/containers/page-popup";
import useItemForm from './useItemForm'
import ProcessLoader from "../../../components/general/process-loader";
import FormActionButtons from "../../../components/inputs/form-action-buttons";
import FileSelector from "../../../components/inputs/file-selector";


export default function Form({formData, onCancel, onSave, parentList, selectedLevel}) {

    const itemName = "Service"
    const nameInputField = nameField("name", "name", itemName, "Enter name", true, 3, 100)
    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const {handleSubmit, apiInProgress, showMessage, message, onFileSelect, uploadInProgress} = useItemForm(onSave, selectedLevel);
    
    const methods = useForm(
        {
            defaultValues: formData ? {
            id: formData.id,
            name: formData.name,
            description: formData.description,
            price: formData.price,
            imageUrl: formData.imageUrl,
            parentId: formData.parentService ? formData.parentService.id : undefined  
            }: {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        handleSubmit(data)
    })

    return (
        <PagePopup title={itemName} onBack={onCancel} showHeader showBackNavigation>
            <FormProvider {...methods}>
                <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                    {selectedLevel.parentLevel &&
                        <Select id="parentId" className="mb-3 col-md-12" name="parentId"  label={`Select ${selectedLevel.parentLevel ? selectedLevel.parentLevel.name : ''}`} list={parentList ? parentList : []} valueProperty="id"  />
                    }
                    <Input id="name" {...nameInputField} showMessage={showMessage} message={message} className="mb-3" required />
                    <Input type="textarea" id="name" {...descriptionInputField} className="mb-3" />
                    <FileSelector name="attachment" existingFiles={formData.files} onFileSelect={onFileSelect} label="Upload File" showProgress={uploadInProgress}/>
                    <FormActionButtons onSubmit={onSubmit} onCancel={onCancel} />
                    {apiInProgress && <ProcessLoader />}
                </form>
            </FormProvider>
        </PagePopup>
    )
}