import { useState, useEffect } from "react"
import { getAll, deleteItem } from "./rest-service"
import ActionButtons from "../../../components/inputs/action-buttons"
import Avatar from "../../../components/general/avatar"

export default function useIndex() {

    const [list, setList] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [loading, setLoading] = useState(true)

    const [showForm, setShowForm] = useState(false)

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const pageTitle = "Specializations"

    const itemName = "Specialization"

    const getHeaders = () => {
        const headers = [
            {title: "Name", type: "label", property: "name"},
            {title: "Description", type: "label", property: "description"},
            {title: "Action", type: "action"}
        ]
        return headers;
    }

    const handleSubmit = async (response) => {
        setShowForm(false)
        fetchAll()
        displayMessage(`${itemName} '${response.name}' has been saved successfully.`)

    }

    const fetchAll = async () => {
        setLoading(true)
        const response = await getAll()
        setList(response)
        setLoading(false)
    }

    const handleAddClick = () => {
        setFormData({name: "", description: ""})
        setShowForm(true)
    }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    const handleEditClick = (event, row) => {
        console.log(row)
        event.stopPropagation()
        if(row.imageUrl) {
            row.files = [{image: row.imageUrl, name:"Hospy"}]
        }else {
            row.files = []
        }
        setFormData(row)
        setShowForm(true)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
        fetchAll()
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        fetchAll()
        setShowConfirmation(false)
        displayMessage(`${itemName} '${selectedItem.name}' has been updated successfully.`)
    }

    const breadcrumbItems = ["Home", "Masters", pageTitle]

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
          },
          {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            cell: row => <div className={`avatar-container ${row.deleted ? 'text-danger' : ''}`} > <Avatar image={row.imageUrl} name={row.name} /> <strong>{row.name}</strong></div>,
        },
        {
            name: 'Description',
            selector: row =><span className={`${row.deleted ? 'text-danger' : ''}`}> {row.description}</span> ,
            sortable: true,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];

    useEffect(() => {
        fetchAll()
    }, [])

    return {
        showForm, 
        list, 
        formData, 
        getHeaders, 
        handleSubmit, 
        loading, 
        handleAddClick, 
        handleCancelClick,
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        breadcrumbItems,
        pageTitle
    }

}