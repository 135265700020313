import { 
    BASE_URL,
    UPLOAD_URL
 } from "../../../service/ApiUrls";
import { post, get, Delete, upload } from "../../../service";

const PACKAGES_PATH = 'hospy-package'

const HOSPITAL_PACKAGES_PATH = 'hospital-package'

const getPackagesApiUrl = () => {
    return `${BASE_URL}${PACKAGES_PATH}/active`;
};

const getHospitalPackagesApiUrl = (id) => {
    if (id) {
        return `${BASE_URL}${HOSPITAL_PACKAGES_PATH}/hospital/${id}` 
    } 
    return `${BASE_URL}${HOSPITAL_PACKAGES_PATH}`; 
};


export const deleteItem = async (data) => {
    const response = await Delete(getHospitalPackagesApiUrl(), data);
    return response;
};

export const uploadFile = async (data) => {
    const response = await upload(UPLOAD_URL, data);
    return response;
};

export const getPackages = async () => {
    const response = await get(getPackagesApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const saveHospitalPackage = async (data) => {
    const response = await post(getHospitalPackagesApiUrl(), data);
    return response;
};

export const getHospitalPackages = async (id) => {
    if(!id) {
        return []
    }
    const response = await get(getHospitalPackagesApiUrl(id));
    if(!response) {
        return []
    }
    return response;
};




