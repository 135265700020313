import { useState } from 'react'
import { saveAll, deleteItem, getAll, uploadFile } from './attachment-rest-service';

export default function useContactNumbers(hospitalId, existingFiles) {

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [files, setFiles] = useState(existingFiles)

    const [selectedItem, setSelectedItem] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const onFileSelect = (files) => {
        setFiles(files)
        console.log(files)
    }

    const handleDeleteClick = async (row) => {
        await deleteItem({id: row.id, deleted: true})
        displayMessage(`Photo has been updated successfully.`)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const fetchAll = async () => {
        const response = await getAll(hospitalId)
        if(response) {
            setFiles(response)
        }
    }

    const onDeleteCancel = async () => {
        setShowConfirmation(false)
        fetchAll()
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        setShowConfirmation(false)
        displayMessage(`Photo has been updated successfully.`)
    }

    const handleSubmit = async () => {
        if(!hospitalId) {
            alert("Cannot upload photos before saving basic info")
            return
        }
        if(files && files.length > 0){
            setUploadInProgress(true)
            let attachments = []
            for(let i = 0; i < files.length; i++) {
                let attachment = await upload(files[i].file)
                attachments.push({hospitalId, attachment})
            }
            const response = await saveAll(attachments)
            setFiles(response.data)
            setUploadInProgress(false)
        }
        displayMessage("Photos are saved successfully.")
    }

    const upload = async (file) => {
        const data = {
            file: file
        }
        let response = await uploadFile(data)
        return response;
    }

    return { 
        showMessage, 
        message,
        handleDeleteClick,
        files,
        onFileSelect,
        showConfirmation,
        onDeleteCancel,
        onDeleteConfirm,
        selectedItem,
        uploadInProgress,
        handleSubmit
    }
}