import { useState, useEffect } from "react"
import { getDoctors, saveHospitalDoctor, deleteItem, getHospitalDoctors } from "./rest-service"
import ActionButtons from "../../../components/inputs/action-buttons"
import Avatar from "../../../components/general/avatar"
import { useForm } from "react-hook-form";


export default function useIndex(hospitalId) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [list, setList] = useState([])

    const [doctors, setDoctors] = useState([])

    const [loading, setLoading] = useState(true)

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const [showForm, setShowForm] = useState(false)

    const itemName = "Hospital Doctor"

    const methods = useForm()

    const handleRowClick = (row) => {
        setSelectedItem(row)
        setShowForm(true)
    }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    const handleSubmit = methods.handleSubmit(data => {
        handleAddClick(data)
    })

    const fetchAll = async () => {
        setLoading(true)
        const response = await getHospitalDoctors(hospitalId)
        setList(response)
        setLoading(false)
    }

    const fetchDoctors = async () => {
        setLoading(true)
        const response = await getDoctors()
        if(response) {
            for(let i in response) {
                let doctor = response[i]
                doctor.name = doctor.firstName
                if(doctor.lastName) {
                    doctor.name += " " + doctor.lastName
                }
            }
        }
        setDoctors(response)
        setLoading(false)
    } 

    const handleAddClick = async (data) => {
        if(!hospitalId) {
            setErrorMessage("Cannot save doctor before saving basic info")
            setShowErrorMessage(true)
            return
        }else {
            setShowErrorMessage(false)
        }
        setApiInProgress(true)
        let payload = {hospital: {id: hospitalId}, doctor: {id: data.doctorId}}
        let response = await saveHospitalDoctor(payload)
        if(!response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            setErrorMessage("")
            setShowErrorMessage(false)
            displayMessage("Hospital doctor has been saved successfully.")
            payload.doctor = getDoctor(response.data.doctor.id)
            setSelectedItem(payload)
            setShowForm(true)
        }
        fetchAll() 
    }

    const getDoctor = (id) => {
        let filteredDoctors = doctors.filter(doctor => doctor.id === id)
        if(filteredDoctors.length > 0) {
            return filteredDoctors[0]
        }
        return undefined;
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowForm(true)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
        fetchAll()
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        fetchAll()
        setShowConfirmation(false)
        displayMessage(`${itemName} '${selectedItem.doctor.name}' has been updated successfully.`)
    }

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span onClick={(event) => handleEditClick(event,row)} className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            cell: row => <div onClick={(event) => handleEditClick(event,row)} className={`avatar-container ${row.deleted ? 'text-danger' : ''}`} > <Avatar image={row.doctor.imageUrl} name={row.doctor.name} /> <strong>{row.doctor.firstName} {row.doctor.lastName}</strong></div>,
        },
        {
            name: 'Qualification',
            selector: row => row.doctor.qualification.name,
            sortable: true,
            cell: row => <div onClick={(event) => handleEditClick(event,row)} className={`avatar-container ${row.deleted ? 'text-danger' : ''}`} >{row.doctor.qualification.name}</div>,
        },
        {
            name: 'Specialization',
            selector: row =><span onClick={(event) => handleEditClick(event,row)} className={`${row.deleted ? 'text-danger' : ''}`}> {row.doctor.specialization.name}</span> ,
            sortable: true,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];

    useEffect(() => {
        fetchAll()
        fetchDoctors()
    }, [])

    return {
        list, 
        loading, 
        handleSubmit,
        handleEditClick,
        handleDeleteClick, 
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        doctors,
        methods,
        apiInProgress,
        errorMessage,
        showErrorMessage,
        showForm,
        handleRowClick,
        handleCancelClick
    }

}