import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { createItem, updateItem, getHospitalTypes, getItem, uploadFile } from './rest-service';
import { useForm } from 'react-hook-form';

export default function useItemForm() {

    const navigate = useNavigate();

    const { id } = useParams();

    const [apiInProgress, setApiInProgress] = useState(false)

    const [loading, setLoading] = useState(true)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [hospitalTypes, setHospitalTypes] = useState([])

    const [formData, setFormData] = useState(undefined);

    const [hospitalId, setHospitalId] = useState(id)

    const [hospital, setHospital] = useState()

    const pageTitle = "Hospital"

    const breadcrumbItems = ["Home", "Hospitals", id ? "edit" :  "create"]

    let data = undefined

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const addressRef = useRef();

    const methods = useForm()

    const onFileSelect = (files) => {
        setFiles(files)
    }

    const fetchHospitalTypes = async () => {
        const response = await getHospitalTypes()
        setHospitalTypes(response)
    }

    const fetchItem = async (id) => {
        setLoading(true)
        const response = await getItem(id)
        if(response && response.type) {
            setHospital(response)
            response.hospitalTypeId = response.type.id
            if(response.imageUrl) {
                response.files = [{image: response.imageUrl, name:"Hospy"}]
            }else {
                response.files = []
            }
            setFormDataValues(response)
        }
        setLoading(false)
    }

    const setFormDataValues = (response) => {
        /*{
            defaultValues: formData ? {
            id: formData.id,
            name: formData.name,
            description: formData.description,
            imageUrl: formData.imageUrl,
            hospitalTypeId: formData.type ? formData.type.id : undefined  
            }: {}
        }*/
        methods.setValue("name", response.name)
        methods.setValue("description", response.description)
        methods.setValue("hospitalTypeId", response.type && response.type.id)



        setFormData(response)
    }

    const onSubmit = methods.handleSubmit(data => {
        addressRef.current?.onSubmit()
        handleSubmit(data)
    })

    const handleSubmit = (payload) => {
        data = payload
    }

    const handleAddressSave = async (address) => {
        setShowErrorMessage(false)
        setApiInProgress(true)
        let payload = {
            id: id, 
            type: {id: data.hospitalTypeId}, 
            name: data.name, 
            description: data.description, 
            imageUrl: hospital && hospital.imageUrl,
            address: {id: address.id}
        }
        if(files && files.length > 0){
            payload.imageUrl = await upload()
        }

        let response;
        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response || !response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            setHospitalId(response.data.id)
            displayMessage(`${pageTitle} '${response.data.name}' has been saved successfully.`)
            navigate("/hospital/"+response.data.id)
        }
        setApiInProgress(false)

    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const upload = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            file: file
        }
        let response = await uploadFile(data)
        setUploadInProgress(false)
        if(response) {
            return response.mediaUrl;
        }
    }

    useEffect(() => {
        fetchHospitalTypes()
        if(id) {
            fetchItem(id)
        } else {
            setLoading(false)
        }
    }, [id])

    return {
        hospitalTypes,
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message,
        showErrorMessage,
        errorMessage, 
        pageTitle,
        breadcrumbItems,
        handleAddressSave,
        formData,
        loading,
        hospitalId,
        uploadInProgress, 
        onFileSelect,
        addressRef,
        methods,
        onSubmit
    }
}