import { useState } from 'react'
import { createItem, updateItem, deleteItem } from './rest-service';
import ActionButtons from '../../components/inputs/action-buttons';

export default function usePreRequestives(packageId, existingItems) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [items, setItems] = useState(existingItems)

    const [selectedItem, setSelectedItem] = useState("")

    const [item, setItem] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)


    const handleInputChange = (event) => {
        setItem(event.target.value)
    }

    const handleAddClick = () => {
        const data = {packageId: packageId, name: item, deleted: false}
        
        const list = [...items, ...[data]]
        setItems(list)
        //handleSubmit(item)
    }

    const handleSubmit = async (data) => {
        setApiInProgress(true)
        let payload = data
        let response;
        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response.success) {
            setMessage(response.data.errors.message)
            setShowMessage(true)
        }else {
            setMessage("")
            setShowMessage(false)
        }
        setApiInProgress(false)
        
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        setShowConfirmation(false)
        displayMessage(`'${selectedItem.contactNumber}' has been updated successfully.`)
    }

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
          },
          {
            name: 'Pre Requestive',
            selector: row => row.name,
            sortable: true,
            cell: row => <div className={`${row.deleted ? 'text-danger' : ''}`} ><strong>{row.name}</strong></div>,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];




    return { 
        apiInProgress, 
        showMessage, 
        message,
        handleInputChange,
        handleAddClick,
        items,
        columns,
        showConfirmation,
        onDeleteCancel,
        onDeleteConfirm,
        selectedItem 
    }
}