import React from "react";

export default function Header() {
    return (
      <div className="navbar-custom">
        <div className="topbar">
          <div className="topbar-menu d-flex align-items-center gap-1">
            <button className="button-toggle-menu">
                <i className="mdi mdi-menu"></i>
            </button>
          </div>
          <div className="topbar-menu d-flex align-items-center">
                  <button className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                      <img src="assets/images/users/user-1.jpg" alt="User" className="rounded-circle" />
                      <span className="ms-1 d-none d-md-inline-block">
                          Admin <i className="mdi mdi-chevron-down"></i>
                      </span>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                      <div className="dropdown-header noti-title">
                          <h6 className="text-overflow m-0">Welcome !</h6>
                      </div>
                  </div>
            </div>
        </div>
      </div>

        
    )
}