import { useState, useEffect } from "react"
import { getPackages, saveHospitalPackage, deleteItem, getHospitalPackages } from "./rest-service"
import ActionButtons from "../../../components/inputs/action-buttons"
import Avatar from "../../../components/general/avatar"
import { useForm } from "react-hook-form";


export default function useIndex(hospitalId) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [list, setList] = useState([])

    const [packages, setPackages] = useState([])

    const [loading, setLoading] = useState(true)

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const itemName = "Hospital Package"

    const methods = useForm()

    const handleSubmit = methods.handleSubmit(data => {
        handleAddClick(data)
    })

    const fetchAll = async () => {
       setLoading(true)
        const response = await getHospitalPackages(hospitalId)
        setList(response)
        setLoading(false)
    }

    const fetchPackages = async () => {
        setLoading(true)
        const response = await getPackages()
        setPackages(response)
        setLoading(false)
    } 

    const handlePriceChange = (event) => {
        console.log(event.target.value)
    }

    const handlePackageChange = (event) => {
        console.log(event.target.value)
    }

    const handleAddClick = async (data) => {
        console.log(data)
        if(!hospitalId) {
            setErrorMessage("Cannot save Packages before saving basic info")
            setShowErrorMessage(true)
            return
        }else {
            setShowErrorMessage(false)
        }
        setApiInProgress(true)
        let payload = {hospital: {id: hospitalId}, pack: {id: data.packageId}, price: data.price}
        let response = await saveHospitalPackage(payload)
        if(!response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            setErrorMessage("")
            setShowErrorMessage(false)
            displayMessage("Hospital Package has been saved successfully.")
        }
        fetchAll() 
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
        fetchAll()
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        fetchAll()
        setShowConfirmation(false)
        displayMessage(`${itemName} '${selectedItem.pack.name}' has been updated successfully.`)
    }

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
          },
          {
            name: 'Name',
            selector: row => row.pack.name,
            sortable: true,
            cell: row => <div className={`avatar-container ${row.deleted ? 'text-danger' : ''}`} > <Avatar image={row.pack.imageUrl} name={row.pack.name} /> <strong>{row.pack.name}</strong></div>,
        },
        {
            name: 'Price',
            selector: row =><span className={`${row.deleted ? 'text-danger' : ''}`}> {row.price}</span> ,
            sortable: true,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];

    useEffect(() => {
        fetchAll()
        fetchPackages()
    }, [])

    return {
        list, 
        loading, 
        handleSubmit,
        handleEditClick,
        handleDeleteClick, 
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        packages,
        handlePriceChange,
        handlePackageChange,
        methods,
        apiInProgress,
        errorMessage,
        showErrorMessage
    }

}