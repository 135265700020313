import React from "react";
import Card from "../../components/containers/card";
import FormHeader from "../../components/general/form-header";
import useContactNumbers from "./usePreRequestives";
import Table from "../../components/containers/table";
import Popover from "../../components/containers/popover";
import Toast from "../../components/general/toast";

export default function PreRequestives({packageId, existingItems}) {

    const {
        apiInProgress, 
        showMessage, 
        message,
        handleInputChange,
        handleAddClick,
        items,
        columns,
        showConfirmation,
        selectedItem ,
        onDeleteCancel,
        onDeleteConfirm

    } = useContactNumbers(packageId, existingItems)

    return(
        <Card>
            <FormHeader title="Tests" />
                <div className="row">
                    <div className="mb-3">
                        <label className="form-label" htmlFor="contactNumber">Test name</label>
                        <div className="input-group">
                            <input id="testName" name="testName" onChange={event => handleInputChange(event)} type="text" className="form-control" placeholder="Enter test name" aria-label="Test name" />
                            <button onClick={() => handleAddClick()} className="btn input-group-text btn-info waves-effect waves-light" type="button">ADD</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Table data={items} className="dataTable" columns={columns} pagination/>
                </div>
                {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.name}'`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} '${selectedItem.name}'?`}</p>
                </Popover>
            }
            
            {showMessage && <Toast text={message} className="success" /> }
        </Card>
    )




}