import './App.css';
import { HashRouter, Route, Routes } from "react-router-dom";
import Dashboard from './pages/dashboard'
import HospitalTypes from './pages/master/hospital-types/HospitalTypes';
import Qualifications from './pages/master/qualifications/Qualifications';
import Facilities from './pages/master/facilities/Facilities';
import Departmetns from './pages/master/departments'
import Specializations from './pages/master/specializations'
import Rooms from './pages/master/rooms'
import Procedures from './pages/master/procedures'
import Services from './pages/master/services'
import Comorbidities from './pages/master/comorbidity'


import Ambulances from './pages/ambulances'
import Ambulance from './pages/ambulances/form'

import Hospitals from './pages/hospitals'
import Hospital from './pages/hospitals/form'

import Doctors from './pages/doctors'
import Doctor from './pages/doctors/form'
import DoctorSearchForm from './pages/doctors/search-form';

import BloodBanks from './pages/blood-banks'
import BloodBank from './pages/blood-banks/form'

import Packages from './pages/hpackages'
import Package from './pages/hpackages/form'

import HospyUsers from './pages/hospy-users'
import HospyUser from './pages/hospy-users/form'

import Login from './pages/login';




function App() {
  return (
      <HashRouter>
        <Routes>
          <Route element={<Dashboard />} path="/dashboard" />

          <Route element={<Login />} path="/" />

          <Route element={<HospitalTypes />} path="/hospital-types" />
          <Route element={<Qualifications />} path="/qualifications" />
          <Route element={<Facilities />} path="/facilities" />
          <Route element={<Departmetns />} path="/departments" />
          <Route element={<Specializations />} path="/specializations" />
          <Route element={<Rooms />} path="/rooms" />
          <Route element={<Procedures />} path="/procedures" />
          <Route element={<Services />} path="/services" />
          <Route element={<Comorbidities />} path="/comorbidities" />

          <Route element={<Ambulances />} path="/ambulances" />
          <Route element={<Ambulance />} path="/ambulance" />
          <Route element={<Ambulance />} path="/ambulance/:id" />

          <Route element={<Hospitals />} path="/hospitals" />
          <Route element={<Hospital />} path="/hospital" />
          <Route element={<Hospital />} path="/hospital/:id" />

          <Route element={<Doctors />} path="/doctors" />
          <Route element={<Doctor />} path="/doctor" />
          <Route element={<Doctor />} path="/doctor/:id" />
          <Route element={<DoctorSearchForm />} path="/doctors/search" />
          <Route element={<Doctor />} path="/doctor/:registrationNumber/state/:stateId" />



          <Route element={<BloodBanks />} path="/blood-banks" />
          <Route element={<BloodBank />} path="/blood-bank" />
          <Route element={<BloodBank />} path="/blood-bank/:id" />

          <Route element={<Packages />} path="/packages" />
          <Route element={<Package />} path="/package" />
          <Route element={<Package />} path="/package/:id" />


          <Route element={<HospyUsers />} path="/hospy-users" />
          <Route element={<HospyUser />} path="/hospy-user" />

          
        </Routes>
      </HashRouter>
  );
}

export default App;
