import React from "react";

export default function Avatar({image, name}) {

    const getInitial = () => {
        if(name && name.length > 0) {
            return name.charAt(0)
        }
        return "";
    }
    return(
        <div className="avatar avatar-md rounded-circle">
            <span className={`avatar-title bg-soft-secondary text-secondary font-20 rounded-circle ${image ? '': 'avatar-bg'}`}  data-bs-toggle="tooltip" data-bs-placement="bottom" title={name}>
                {
                image ? <img src={image} alt={name} /> : <span className="avatar-initial">{getInitial()}</span>
                }
            </span>
        </div>
    )
}