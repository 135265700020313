
import React from 'react'
import { findInputError, isFormInvalid } from '../../utils'
import { useFormContext } from 'react-hook-form'
import { AnimatePresence, motion } from 'framer-motion'
import { MdError } from 'react-icons/md'

export const Input = ({
  name,
  label,
  type = "text",
  id,
  placeholder,
  validation,
  className,
  multiline,
  disabled = false,
  showMessage = false,
  message,
  onChange,
  defaultValue,
  required = false
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const inputErrors = findInputError(errors, name)
  const isInvalid = isFormInvalid(inputErrors)

  const handleInputChange = (event) => {
    if(onChange) {
      onChange(event)

    }
  }

  return (
    <div className={className ? className : ""} >
      <div className="flex justify-between">
        <label htmlFor={id} className="form-label">
          {label}{required && "*"}
        </label>
        <AnimatePresence mode="wait" initial={false}>
          {isInvalid && (
            <InputError
              message={inputErrors.error.message}
              key={inputErrors.error.message}
            />
          )}
          {showMessage && (
            <InputError
              message={message}
              key="message"
            />
          )}
        </AnimatePresence>
      </div>
      {!multiline ?
        <input
          id={id}
          className="form-control"
          placeholder={placeholder}
          {...register(name, validation)}
          type={type}
          disabled={disabled}
          onChange={event => handleInputChange(event)}
          defaultValue={defaultValue}
        />
        :
        <textarea
          id={id}
          type="textarea"
          className="form-control"
          placeholder={placeholder}
          {...register(name, validation)}
          defaultValue={defaultValue}
        />
      }
    </div>
  )
}

const InputError = ({ message }) => {
  return (
    <motion.p
      className="error-message"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  )
}

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
}
