import { useState, useEffect } from "react"
import { getAll, deleteItem } from "./rest-service"
import ActionButtons from "../../components/inputs/action-buttons"
import Avatar from "../../components/general/avatar"
import { useNavigate } from "react-router-dom";


export default function useIndex() {

    const navigate = useNavigate();

    const [list, setList] = useState([])

    const [loading, setLoading] = useState(true)

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const pageTitle = "Blood Banks"

    const itemName = "Blood Bank"

    const getHeaders = () => {
        const headers = [
            {title: "Name", type: "label", property: "name"},
            {title: "Description", type: "label", property: "description"},
            {title: "Action", type: "action"}
        ]
        return headers;
    }

    const fetchAll = async () => {
        setLoading(true)
        const response = await getAll()
        setList(response)
        setLoading(false)
    }

    const handleAddClick = () => {
        navigate("/blood-bank")
    }

    const handleEditClick = (event, row) => {
        console.log(row)
        event.stopPropagation()
        navigate("/blood-bank/"+row.id)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
        fetchAll()
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        fetchAll()
        setShowConfirmation(false)
        displayMessage(`${itemName} '${selectedItem.name}' has been updated successfully.`)
    }

    const breadcrumbItems = ["Home", "Masters", pageTitle]

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            cell: row => <div className={`avatar-container ${row.deleted ? 'text-danger' : ''}`} > <Avatar image={row.imageUrl} name={row.name} /> <strong>{row.name}</strong></div>,
        },
        {
            name: 'City',
            selector: row =><span className={`${row.deleted ? 'text-danger' : ''}`}> {row.address.city.name}</span> ,
            sortable: true,
        },
        {
            name: 'Contact number',
            selector: row =><span className={`${row.deleted ? 'text-danger' : ''}`}> {row.displayMobileNumber}</span> ,
            sortable: true,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];

    useEffect(() => {
        fetchAll()
    }, [])

    return {
        list, 
        getHeaders, 
        loading, 
        handleAddClick, 
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        breadcrumbItems,
        pageTitle
    }

}