import { createRef, useState } from 'react'

export default function useFileUploader(onFileSelect, existingFiles, onDelete) {

    const fileRef = createRef()

    const [files, setFiles] = useState(existingFiles)

    const [openFile, setOpenFile] = useState(false)

    const [selectedFile, setSelectedFile] = useState();

    const openFileSelector = () => {
        fileRef.current.click()
    }

    const closeFile = (event) => {
        event.stopPropagation()
        setOpenFile(false)
    }

    const onFileClick = (event, file) => {
        event.stopPropagation()
        setSelectedFile(file)
        setOpenFile(true)
    }

    const removeFile = (event, file) => {
        event.stopPropagation()
        let newFiles = []
        if(file.attachment) {
            newFiles = files.filter(item => item.attachment.id !== file.attachment.id)
        }else {
            newFiles = files.filter(item => item.image !== file.image)
        }
        setFiles(newFiles)
        if(onDelete) {
            onDelete(file)
        }

    }

    const handleFileSelect = (event) => {
        if (event.target.files) {
            let newFiles = []
            for(let i = 0; i < event.target.files.length; i++) {
                let selectedFile = event.target.files[i];
                newFiles.push({
                    name: selectedFile.name,
                    image: URL.createObjectURL(selectedFile),
                    type: selectedFile.type,
                    file: selectedFile
                })
            }
            setFiles([...files, ...newFiles])
            if(onFileSelect) {
                onFileSelect(newFiles)
            }
        }
        
    }

    return { fileRef, openFileSelector, handleFileSelect, files, openFile, onFileClick, selectedFile, closeFile, removeFile }
}
