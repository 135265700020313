import { 
    BASE_URL,
    UPLOAD_URL
 } from "../../../service/ApiUrls";
import { post, get, Delete, upload } from "../../../service";

const COMORBIDITY_PATH = 'comorbidity'

const HOSPITAL_COMORBIDITIES_PATH = 'hospital-comorbidity'

const getComorbiditiesApiUrl = () => {
    return `${BASE_URL}${COMORBIDITY_PATH}/active`;
};

const getHospitalComorbiditiesApiUrl = (id) => {
    if (id) {
        return `${BASE_URL}${HOSPITAL_COMORBIDITIES_PATH}/hospital/${id}` 
    } 
    return `${BASE_URL}${HOSPITAL_COMORBIDITIES_PATH}`; 
};


export const deleteItem = async (data) => {
    const response = await Delete(getHospitalComorbiditiesApiUrl(), data);
    return response;
};

export const uploadFile = async (data) => {
    const response = await upload(UPLOAD_URL, data);
    return response;
};

export const getComorbidities = async () => {
    const response = await get(getComorbiditiesApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const saveHospitalComorbidity = async (data) => {
    const response = await post(getHospitalComorbiditiesApiUrl(), data);
    return response;
};

export const getHospitalComorbidities = async (id) => {
    if(!id) {
        return []
    }
    const response = await get(getHospitalComorbiditiesApiUrl(id));
    if(!response) {
        return []
    }
    return response;
};




