import React, { useState } from "react";

export default function ToggleButton({selected, label, id, onChange, data}) {
    const [checked, setChecked] = useState(selected)

    const handleChecked = (event) => {
        setChecked(!checked)
        data.deleted = !checked
        onChange(event, data)
    }
    return (
        <div className="form-check form-switch">
            <input type="checkbox" defaultChecked={!selected} value={!selected} onChange={event => handleChecked(event)} className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
    )
}