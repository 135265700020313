import { 
    BASE_URL,
    getStatesApiUrl,
    getCountriesApiUrl,
    getCitiesApiUrl,
    getDistrictsApiUrl
 } from "../../service/ApiUrls";
import { post, get, put, Delete } from "../../service";

const PATH = 'address'
  
const getApiUrl = (id) => {
    return id ? `${BASE_URL}${PATH}/${id}` : `${BASE_URL}${PATH}`;
};

export const getItem = async (id) => {
    const response = await get(getApiUrl(id));
    return response;
};

export const createItem = async (data) => {
    const response = await post(getApiUrl(), data);
    return response;
};

export const updateItem = async (data) => {
    const response = await put(getApiUrl(), data);
    return response;
};

export const deleteItem = async (data) => {
    const response = await Delete(getApiUrl(), data);
    return response;
};

export const getCountries = async () => {
    const response = await get(getCountriesApiUrl());
    return response;
};

export const getStates = async (id) => {
    const response = await get(getStatesApiUrl(id));
    return response;
};

export const getDistricts = async (id) => {
    const response = await get(getDistrictsApiUrl(id));
    return response;
};

export const getCities = async (id) => {
    const response = await get(getCitiesApiUrl(id));
    return response;
};