import React from "react";
import Layout from "../../components/layout";
import useIndex from "./useIndex";
import Card from "../../components/containers/card";
import Loader from "../../components/general/loader";
import NoDataMessage from "../../components/general/NoDataMessage";
import PageTitle from "../../components/general/page-title";
import FeatherIcon from 'feather-icons-react';


export default function Index() {
    const {
        list, 
        loading, 
        breadcrumbItems,
        pageTitle 
    } = useIndex()
    
    return(
        <Layout>
            <PageTitle breadCrumbItems={breadcrumbItems} title={pageTitle} />
            {loading ? <Loader /> :<>
                    {list && list.length > 0 ? 
                        <div className="row">
                            {list.map((item, index) => 
                            <div key={index} className="col-md-4 col-xl-4">
                                <a href={"#/"+item.url} className="menu-link">
                                    <div className="widget-rounded-circle card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className={`avatar-lg rounded-circle ${item.bgColor} border-success border`}>
                                                        <FeatherIcon icon={item.icon} className="dashboard-count-icon text-success" />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="text-end">
                                                        <h3 className="text-dark mt-1"><span data-plugin="counterup">{item.activeCount}/{item.totalCount}</span></h3>
                                                        <p className="text-muted mb-1 text-truncate">{item.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            )}
                        </div>
                        : 
                        <Card>
                            <NoDataMessage />
                        </Card>
                    }
                </>
            }

        </Layout>
    )
}