import { useState } from 'react'
import { createItem, updateItem } from './rest-service';

export default function useItemForm(onSave, level) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")


    const handleSubmit = async (data) => {
        setApiInProgress(true)
        let payload = {
            id: data.id, 
            name: data.name, 
            description: data.description, 
            procedureLevelId: level.id,
            parentProcedure: data.parentId ? {id: data.parentId} : undefined
        }

        console.log(payload)

        let response;
        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response.success) {
            setMessage(response.data.errors.message)
            setShowMessage(true)
        }else {
            setMessage("")
            setShowMessage(false)
            if(onSave) {
                onSave(data)
            }
        }
        setApiInProgress(false)
        
    }

    return {handleSubmit, apiInProgress, showMessage, message}
}