import React from "react";
import FeatherIcon from 'feather-icons-react';



function MenuLink({data}) {
    return(
        <li className={`menu-item${data.active ? ' active' : ''}`}>
          <a href={data.link} className="menu-link">
            <span className="menu-icon">
              <FeatherIcon icon={data.icon} />
            </span>
            <span className="menu-text">{data.title}</span>
          </a>
        </li>
    )
}

function MenuHeader({data}) {
    return(
      <li className="menu-title">{data.title}</li>
    )
}

export default function MenuItem({data}) {
    return(
        <>{data.type === 'menu' ? <MenuLink data={data} /> : <MenuHeader data={data} />}</>
    )
}