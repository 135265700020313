import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { createItem, updateItem, getHospitals, getItem } from './rest-service';

export default function useItemForm() {

    const navigate = useNavigate();

    const { id } = useParams();

    const [apiInProgress, setApiInProgress] = useState(false)

    const [loading, setLoading] = useState(true)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [hospitals, setHospitals] = useState([])

    const pageTitle = "Blood Bank"

    const breadcrumbItems = ["Home", "Blood Banks", id ? "edit" :  "create"]

    const [formData, setFormData] = useState(undefined);

    const [bloodBankId, setBloodBankId] = useState(id)

    let data = undefined

    const fetchHospitals = async () => {
        const response = await getHospitals()
        setHospitals(response)
    }

    const fetchItem = async (id) => {
        setLoading(true)
        const response = await getItem(id)
        if(response && response.hospital) {
            response.hospitalId = response.hospital.id
        }
        setFormData(response)
        setLoading(false)
    }

    const handleSubmit = (payload) => {
        data = payload
    }

    const handleAddressSave = async (address) => {
        setErrorMessage("")
        setShowErrorMessage(false)
        setApiInProgress(true)
        let payload = {id: id, hospital: {id: data.hospitalId}, name: data.name, description: data.description, address: {id: address.id}}

        let response;
        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response || !response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            setBloodBankId(response.data.id)
            displayMessage(`${pageTitle} '${response.data.name}' has been saved successfully.`)
            navigate("/blood-bank/"+response.data.id)
            
        }
        setApiInProgress(false)
        

    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    useEffect(() => {
        fetchHospitals()
        if(id) {
            fetchItem(id)
        } else {
            setLoading(false)
        }
    }, [id])
    
    return {
        hospitals,
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message,
        showErrorMessage,
        errorMessage, 
        pageTitle,
        breadcrumbItems,
        handleAddressSave,
        formData,
        loading,
        bloodBankId
    }
}