import React, { useRef } from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../utils/inputValidations";
import { Input } from "../../components/inputs/input";

import ProcessLoader from "../../components/general/process-loader";
import Card from "../../components/containers/card";
import PageTitle from "../../components/general/page-title";
import Layout from "../../components/layout";
import FormHeader from "../../components/general/form-header";
import Select from "../../components/inputs/select";
import ContactNumbers from "./contact-numbers";
import Address from "../../components/address";
import Loader from "../../components/general/loader";
import useItemForm from "./useItemForm";
import Photos from "./photos";
import Toast from "../../components/general/toast";
import FileSelector from "../../components/inputs/file-selector";
import Comorbidities from "./comorbidities";
import Facilities from "./facilities"
import HospitalDoctors from "./doctors";
import HospitalPackages from "./packages";
import HospitalServices from "./services-new";
import HospitalProcedures from "./procedures";
import HospitalTimings from "./timings";


export default function Form() {
    const nameInputField = nameField("name", "name", "Hospital *", "Enter name", true, 3, 100)
    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const {
        hospitalTypes,        
        apiInProgress, 
        showMessage, 
        message,
        showErrorMessage,
        errorMessage,  
        pageTitle,
        breadcrumbItems,
        handleAddressSave,
        formData,
        loading,
        hospitalId,
        onFileSelect,
        uploadInProgress,
        addressRef,
        methods,
        onSubmit
    } = useItemForm()

    return (
        <Layout>
            <PageTitle title={pageTitle} breadCrumbItems={breadcrumbItems} />
            {loading  ? <Loader/> :<>
                <ul className="nav nav-tabs nav-bordered mb-3" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a href="#basic-info" data-bs-toggle="tab" aria-expanded="false" className="nav-link active" aria-selected="false" role="tab" tabIndex="-1">
                            Basic info
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#timings" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="0">
                            Timings
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#comorbities" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="0">
                            Comorbidities
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#services" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="1">
                            Services
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#procedures" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="1">
                            Procedures
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#facilities" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="1">
                            Facilities
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#packages" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="1">
                            Packages
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#doctors" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="1">
                            Doctors
                        </a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div id="basic-info" className="tab-pane active">
                                <div className="row">
                            <div className="col-lg-6">
                                <Card>
                                    <FormHeader title="General" />
                                    <FormProvider {...methods}>
                                        <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Select id="hospitalTypeId" name="hospitalTypeId"  label="Hospital type*"  valueProperty="id" className="mb-2" list={hospitalTypes} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input defaultValue={formData && formData.name} id="name" {...nameInputField} showMessage={showErrorMessage} message={errorMessage} className="mb-2"  />
                                                </div>
                                                <div className="col-md-12">
                                                    <Input defaultValue={formData && formData.description} type="textarea" id="description" {...descriptionInputField} className="mb-2" />
                                                </div>
                                                <div className="col-md-12">
                                                    <FileSelector className="logo-uploader" name="attachment" existingFiles={formData ? formData.files : []} onFileSelect={onFileSelect} label="Upload Logo" showProgress={uploadInProgress}/>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </FormProvider>
                                </Card>
                                <Address formData={formData && formData.address} onSave={handleAddressSave} ref={addressRef} />
                            </div>
                            <div className="col-lg-6">
                                <ContactNumbers existingNumbers={formData && formData.contactNumbers ? formData.contactNumbers : []} hospitalId={hospitalId} />
                                <Photos existingFiles={formData && formData.attachments ? formData.attachments : []} hospitalId={hospitalId} />
                            </div>
                        </div>
                        <div className="p-2 bt-1">
                            <div className="col-md-12">
                                <button className="btn btn-md btn-info waves-effect waves-light pull-right" onClick={onSubmit}>Save</button>
                            </div>
                        </div>
                    </div>
                    <div id="timings" className="tab-pane">
                        <HospitalTimings hospitalId={hospitalId} />
                    </div>
                    <div id="comorbities" className="tab-pane">
                        <Comorbidities hospitalId={hospitalId} />
                    </div>
                    <div id="services" className="tab-pane">
                        <HospitalServices hospitalId={hospitalId} />
                    </div>
                    <div id="procedures" className="tab-pane">
                        <HospitalProcedures hospitalId={hospitalId} />
                    </div>
                    <div id="facilities" className="tab-pane">
                        <Facilities hospitalId={hospitalId} />
                    </div>
                    <div id="packages" className="tab-pane">
                        <HospitalPackages hospitalId={hospitalId} />
                    </div>
                    <div id="doctors" className="tab-pane">
                        <HospitalDoctors hospitalId={hospitalId} />
                    </div>
                </div>
            </>
            }
            {apiInProgress && <ProcessLoader />}
            {showMessage && <Toast text={message} className="success" /> }
        </Layout>
    )
}