import { useState, useEffect } from 'react'
import { createItem, getCities, getCountries, getStates, getDistricts, updateItem } from './rest-service';
import { useParams } from 'react-router-dom';

export default function useAddress(onSave, formData) {

    const { stateId } = useParams();

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [countries, setCountries] = useState([])

    const [states, setStates] = useState([])

    const [districts, setDistricts] = useState([])

    const [cities, setCities] = useState([])

    const [selectedCountry, setSelectedCountry] = useState("")

    const [selectedCity, setSelectedCity] = useState("")

    const [selectedState, setSelectedState] = useState(stateId ? stateId : "")

    const [selectedDistrict, setSelectedDistrict] = useState("")


    const fetchCountries = async () => {
        const response = await getCountries()
        setCountries(response);
    }

    const fetchStates = async (id) => {
        const response = await getStates(id)
        setStates(response);
    }

    const fetchDistricts = async (id) => {
        const response = await getDistricts(id)
        setDistricts(response);
    }

    const fetchCities = async (id) => {
        const response = await getCities(id)
        setCities(response);
    }

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value)
        if(event.target.value !== "") {
            fetchStates(event.target.value)
        }
    }

    const handleStateChange = (event) => {
        setSelectedState(event.target.value)
        if(event.target.value !== "") {
            fetchDistricts(event.target.value)
        }
    }

    const handleDistrictChange = (event) => {
        setSelectedDistrict(event.target.value)
        if(event.target.value !== "") {
            fetchCities(event.target.value)
        }
    }

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value)
    }

    const handleSubmit = async (data) => {
        let payload = {id: data.id, addressLine1: data.addressLine1}
        payload.country = {id: data.countryId}
        payload.state = {id: data.stateId}
        payload.district = {id: data.districtId}
        payload.city = {id: data.cityId}
        let response;

        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response.success) {
            setMessage(response.data.errors.message)
            setShowMessage(true)
        }else {
            setMessage("")
            setShowMessage(false)
            if(onSave) {
                onSave(response.data)
            }
        }
    }
    useEffect(() => {
        fetchCountries()
        if(formData) {
            fetchStates(formData.country.id)
            fetchDistricts(formData.state.id)
            fetchCities(formData.district.id)
        }
        if(stateId) {
            fetchStates()
        }
    }, [formData])
    return {
        handleSubmit, 
        showMessage, 
        message,
        selectedCountry,
        selectedState,
        selectedDistrict,
        selectedCity, 
        countries,
        districts,
        states,
        cities,
        handleCountryChange,
        handleStateChange,
        handleDistrictChange,
        handleCityChange
    }
}