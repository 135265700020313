import { useState, useEffect } from "react"
import { getDays, saveHospitalTime, deleteItem, getHospitalTimings } from "./rest-service"
import ActionButtons from "../../../components/inputs/action-buttons"
import { useForm } from "react-hook-form";
import { formatTime } from "../../../utils/formatters";


export default function useIndex(hospitalId) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [list, setList] = useState([])

    const [days, setDays] = useState([])

    const [loading, setLoading] = useState(true)

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const itemName = "Hospital Timing"

    const methods = useForm()

    const handleSubmit = methods.handleSubmit(data => {
        handleAddClick(data)
    })

    const fetchAll = async () => {
        setLoading(true)
        const response = await getHospitalTimings(hospitalId)
        setList(response)
        setLoading(false)
    }

    const fetchDays = async () => {
        setLoading(true)
        const response = await getDays()
        setDays(response)
        setLoading(false)
    } 

    const handleDayChange = (event) => {
        console.log(event.target.value)
    }

    const handleCancel = () => {
        methods.reset()
    }

    const handleAddClick = async (data) => {
        console.log(data)
        if(!hospitalId) {
            setErrorMessage("Cannot save timing before saving basic info")
            setShowErrorMessage(true)
            return
        }else {
            setShowErrorMessage(false)
        }
        setApiInProgress(true)
        let payload = {hospital: {id: hospitalId}, day: {id: data.dayId}, startTime: data.startTime, endTime: data.endTime}
        if(selectedItem && selectedItem.id) {
            payload.id = selectedItem.id
        }
        console.log(payload)
        let response = await saveHospitalTime(payload)
        if(!response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            setErrorMessage("")
            setShowErrorMessage(false)
            displayMessage("Hospital timing has been saved successfully.")
            methods.reset()
        }
        fetchAll() 
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
        setSelectedValues(row)
    }

    const handleRowClick = (row) => {
        setSelectedValues(row)
    }

    const setSelectedValues = (row) => {
        setSelectedItem(row)
        methods.reset()
        methods.setValue("dayId", row.day.id)
        methods.setValue("startTime", row.startTime)
        methods.setValue("endTime", row.endTime)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
        fetchAll()
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        fetchAll()
        setShowConfirmation(false)
        displayMessage(`${itemName} has been updated successfully.`)
    }

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            cell: row => <div onClick={(event) => handleEditClick(event,row)} className={`avatar-container ${row.deleted ? 'text-danger' : ''}`} > <strong>{row.day.name}</strong></div>,
        },
        {
            name: 'Start time',
            selector: row => row.startTime,
            sortable: true,
            cell: row => <div onClick={(event) => handleEditClick(event,row)} className={`${row.deleted ? 'text-danger' : ''}`} >{formatTime(row.startTime)}</div>,
        },
        {
            name: 'End time',
            selector: row =><span onClick={(event) => handleEditClick(event,row)} className={`${row.deleted ? 'text-danger' : ''}`}> {formatTime(row.endTime)}</span>, 
            sortable: true,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];

    useEffect(() => {
        fetchAll()
        fetchDays()
    }, [])

    return {
        list, 
        loading, 
        handleSubmit,
        handleEditClick,
        handleDeleteClick, 
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        days,
        handleDayChange,
        methods,
        apiInProgress,
        errorMessage,
        showErrorMessage,
        handleCancel,
        handleRowClick
    }

}