import moment from "moment";

const dateTimeFormateFull = "DD/MMM/YYYY hh:mm a"
const dateFormate = "YYYY-MM-DD"

export const getFormattedDateAndTime = (date) => {
    return moment(date).format(dateTimeFormateFull)
}

export const getFormattedDate = (date) => {
    return moment(date).format(dateFormate)
}

export const formatTime = (timeString)=> {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}

