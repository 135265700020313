import React from "react";

export default function FormActionButtons({onSubmit, onCancel}) {

    return(
        <div className='button-list d-grid'>
            {/** 
            <button id="saveAndAddAnotherBtn" className="btn btn-md btn-primary waves-effect waves-light" onClick={onSubmit}>Save And Add Another</button>
            */}
            <button id="saveBtn" className="btn btn-md btn-info waves-effect waves-light" onClick={onSubmit}>Save</button>
            <button className="btn btn-md btn-outline-secondary waves-effect waves-light" onClick={onCancel}>Cancel</button>
        </div>
    )
}