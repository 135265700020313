import { useState, useEffect } from "react";


export default function useMenu() {

    const [items, setItems] = useState([])

    useEffect(() => {

        let items = []
        items.push({ menuName: "menuName0", title: "Dashboard", link: `#/dashboard`, icon: "airplay", type: "menu", active: true })
        items.push({ menuName: "menuName1", title: "Hospitals", link: `#/hospitals`, icon: "grid", type: "menu", active: false })
        items.push({ menuName: "menuName02", title: "Doctors", link: `#/doctors`, icon: "user-check", type: "menu", active: false })
        items.push({ menuName: "menuName4", title: "Ambulances", link: `#/ambulances`, icon: "truck", type: "menu", active: false })
        items.push({ menuName: "menuName4", title: "Blood Banks", link: `#/blood-banks`, icon: "droplet", type: "menu", active: false })
        items.push({ menuName: "menuName4", title: "Hospy Packages", link: `#/packages`, icon: "server", type: "menu", active: false })
        items.push({ menuName: "menuName4", title: "Hospy Users", link: `#/hospy-users`, icon: "user-plus", type: "menu", active: false })


        items.push({ menuName: "menuName5", title: "Masters", type: "menu-header", active: false })

        items.push({ menuName: "menuName6", title: "Hospital Types", link: `#/hospital-types`, icon: "align-center", type: "menu", active: false })
        items.push({ menuName: "menuName9", title: "Facilities", link: `#/facilities`, icon: "map", type: "menu", active: false })
        items.push({ menuName: "menuName6", title: "Departments", link: `#/departments`, icon: "activity", type: "menu", active: false })
        items.push({ menuName: "menuName7", title: "Qualification", link: `#/qualifications`, icon: "file-text", type: "menu", active: false })
        items.push({ menuName: "menuName8", title: "Specialization", link: `#/specializations`, icon: "cast", type: "menu", active: false })
        items.push({ menuName: "menuName9", title: "Rooms", link: `#/rooms`, icon: "server", type: "menu", active: false })
        items.push({ menuName: "menuNam2", title: "Procedures", link: `#/procedures`, icon: "align-justify", type: "menu", active: false })
        items.push({ menuName: "menuName3", title: "Services", link: `#/services`, icon: "life-buoy", type: "menu", active: false })
        items.push({ menuName: "menuName3", title: "Comorbidity", link: `#/comorbidities`, icon: "command", type: "menu", active: false })


        items.push({ menuName: "menuName10", title: "V 1.1.1", type: "menu-header", active: false })

        setItems(items)
    }, [])

    return { items }
}