import { 
    BASE_URL,
    UPLOAD_URL
 } from "../../../service/ApiUrls";
import { post, get, put, Delete, upload } from "../../../service";

const PATH = 'room'

const getAllApiUrl = () => {
    return `${BASE_URL}${PATH}`;
};
  
const getApiUrl = (id) => {
    return id ? `${BASE_URL}${PATH}/${id}` : `${BASE_URL}${PATH}`;
};

export const getAll = async () => {
    const response = await get(getAllApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const getItem = async (id) => {
    const response = await get(getApiUrl(id));
    return response;
};

export const createItem = async (data) => {
    const response = await post(getApiUrl(), data);
    return response;
};

export const updateItem = async (data) => {
    const response = await put(getApiUrl(), data);
    return response;
};

export const deleteItem = async (data) => {
    const response = await Delete(getApiUrl(), data);
    return response;
};

export const uploadFile = async (data) => {
    const response = await upload(UPLOAD_URL, data);
    return response;
};

