import React from 'react'
import  useFileUploader  from '../../hooks/useFileUploader'
import HDiv from '../containers/hdiv'
import VDiv from '../containers/vdiv'
import HGrow from '../elements/h-grow'
import Text from '../elements/texts/text'
import AddButton from './add-button'
import ProcessLoader from '../general/process-loader'
const FileSelector = ({name = "attachment", label, className, onFileSelect, existingFiles, showPreview = true, multiple = false, showProgress = false, onDelete}) => {

    const {fileRef, openFileSelector, handleFileSelect, files, openFile, selectedFile, onFileClick, closeFile, removeFile} = useFileUploader(onFileSelect, existingFiles, onDelete)

    return(
        <VDiv className={`edc-input-group file-uploader mb-2 ${className ? className : ''}`} onClick={openFileSelector}>
            <input name={name} className='hidden' type="file" onChange={(event) => handleFileSelect(event)} ref={fileRef} multiple={multiple} />
            <HDiv>
                <label className="form-label">{label}</label>
                <HGrow />
                <AddButton />
            </HDiv>
            {showPreview && files && multiple ? 
            <HDiv className="mt-3 mb-3 relative">
                {files.map((file, index) =>
                    <div key={"file"+index}>{ !file.deleted &&
                    <VDiv onClick={(event) => onFileClick(event, file)} className='file-preview' style={{backgroundImage: `url(${file.attachment ? file.attachment.mediaUrl : file.image})`}}>
                        <Text size="sm" className="file-name"/>
                        <img className='close' src="/assets/images/icons/close-btn.png" alt="close" onClick={(event) => removeFile(event, file)} />
                    </VDiv>
                    }
                    </div>
                )}
                {showProgress &&
                    <ProcessLoader />
                }
            </HDiv> : null}
            {showPreview && files && !multiple && files.map((file) => 
                <VDiv onClick={(event) => onFileClick(event, file)}  className="cover-preview mt-3 relative" key={file.name} style={file.type === 'video/mp4' ? {}: {backgroundImage: `url(${file.image})`}}>
                    { file.type === 'video/mp4' &&
                    <video className='video' width="100%" height={"100%"} controls>
                        <source src={file.image} type="video/mp4" />
                    </video>
                    }
                    {showProgress &&
                        <ProcessLoader />
                    }
                </VDiv>
            )}
            {openFile ?
            <VDiv className="show-file-container">
                <img className='file' alt={selectedFile.name} src={selectedFile.attachment ? selectedFile.attachment.mediaUrl : selectedFile.image} />
                <img className='close-btn' src='/assets/images/icons/icon-close-white.png' alt='close' onClick={(event) => closeFile(event)} />
            </VDiv>
            : null
            }
        </VDiv>
    )
}

export default FileSelector