import { useState } from 'react'
import { createItem, updateItem, deleteItem, getAll } from './contact-number-rest-service';
import ActionButtons from '../../components/inputs/action-buttons';

export default function useContactNumbers(ambulanceId, existingNumbers) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [numbers, setNumbers] = useState(existingNumbers)

    const [selectedItem, setSelectedItem] = useState("")

    const [contactNumber, setContactNumber] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")


    const handleInputChange = (event) => {
        setContactNumber(event.target.value)
        setShowErrorMessage(false)
    }

    const handleAddClick = () => {
        const item = {ambulanceId: ambulanceId, contactNumber: contactNumber, deleted: false}
        handleSubmit(item)
    }

    const handleSubmit = async (data) => {
        if(!ambulanceId) {
            setErrorMessage("Cannot add contact number before saving basic info")
            setShowErrorMessage(true)
            return
        }else {
            setShowErrorMessage(false)
        }

        setApiInProgress(true)
        let payload = data
        let response;
        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            setErrorMessage("")
            setShowErrorMessage(false)
            
        }
        fetchAll()        
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const fetchAll = async () => {
        setApiInProgress(true)
        const response = await getAll(ambulanceId)
        if(response) {
            setNumbers(response)
        }
        setApiInProgress(false)
    }

    const onDeleteCancel = async () => {
        
        setShowConfirmation(false)
        fetchAll()
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        setShowConfirmation(false)
        displayMessage(`'${selectedItem.contactNumber}' has been updated successfully.`)
    }

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
          },
          {
            name: 'Contact number',
            selector: row => row.contactNumber,
            sortable: true,
            cell: row => <div className={`${row.deleted ? 'text-danger' : ''}`} ><strong>{row.contactNumber}</strong></div>,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];




    return { 
        apiInProgress, 
        showMessage, 
        message,
        handleInputChange,
        handleAddClick,
        numbers,
        columns,
        showConfirmation,
        onDeleteCancel,
        onDeleteConfirm,
        selectedItem,
        errorMessage,
        showErrorMessage 
    }
}