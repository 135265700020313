import React from "react";
import Layout from "../../components/layout";
import useIndex from "./useIndex";
import Table from "../../components/containers/table";
import Card from "../../components/containers/card";
import Loader from "../../components/general/loader";
import Popover from "../../components/containers/popover";
import Toast from "../../components/general/toast";
import NoDataMessage from "../../components/general/NoDataMessage";
import PageTitle from "../../components/general/page-title";

export default function Index() {
    const {
        list, 
        loading, 
        handleAddClick, 
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        handleRowClick,
        message,
        selectedItem,
        columns,
        breadcrumbItems,
        pageTitle 
    } = useIndex()
    
    return(
        <Layout>
            <PageTitle breadCrumbItems={breadcrumbItems} title={pageTitle} />
            {loading ? <Loader /> :
                <Card onAdd={handleAddClick} showAddButton={list && list.length > 0}>
                    {list && list.length > 0 ? 
                        <Table data={list} className="dataTable" columns={columns} pagination onRowClicked={handleRowClick}/>
                        : <NoDataMessage onAdd={handleAddClick} showAddButton />
                    }
                </Card>
            }
            {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.name}'`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} '${selectedItem.name}'?`}</p>
                </Popover>
            }
            {showMessage && <Toast text={message} className="success" /> }
        </Layout>
    )
}