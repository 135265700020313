import { 
    BASE_URL,
 } from "../../../service/ApiUrls";
import { post, get, Delete } from "../../../service";

const SERVICES_PATH = 'service'

const LEVELS_PATH = 'service-level/active'

const HOSPITAL_SERVICE_PATH = 'hospital-service'

const getServicesApiUrl = () => {
    return `${BASE_URL}${SERVICES_PATH}`;
};

const getAllApiUrl = (hospitalId, levelId) => {
    return `${BASE_URL}${HOSPITAL_SERVICE_PATH}/hospital/${hospitalId}/level/${levelId}`;
};
  
const getApiUrl = (id) => {
    return id ? `${BASE_URL}${HOSPITAL_SERVICE_PATH}/${id}` : `${BASE_URL}${HOSPITAL_SERVICE_PATH}`;
};

export const getAll = async (hospitalId, levelId) => {
    if(!hospitalId) {
        return []
    }
    const response = await get(getAllApiUrl(hospitalId, levelId));
    if(!response) {
        return []
    }
    return response;
};

export const saveItem = async (data) => {
    const response = await post(getApiUrl(), data);
    return response;
};


export const deleteItem = async (data) => {
    const response = await Delete(getApiUrl(), data);
    return response;
};

export const getAllLevels = async () => {
    const response = await get(`${BASE_URL}${LEVELS_PATH}`);
    if(!response) {
        return []
    }
    return response;
};

export const getServicesByLevel = async (id) => {
    const response = await get(`${getServicesApiUrl()}/level/${id}`);
    if(!response) {
        return []
    }
    return response;
};

