import React from 'react'

export default function Card({children, onAdd, showAddButton, className}) {
    return (
        <div className={`card${className ? ' '+className : ''}`} >
            <div className="card-body">
                {showAddButton && 
                <div className="row mb-2">
                    <div className="col-lg-8">                          
                    </div>
                    <div className="col-lg-4">
                        <div className="text-lg-end">
                            <button onClick={() => onAdd()} type="button" className="btn btn-info waves-effect waves-light mb-2 me-2"><i className="mdi mdi-basket me-1"></i> Add New </button>
                        </div>
                    </div>
                </div>
                }
                {children}
            </div>
        </div>
    )
}