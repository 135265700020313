import React from 'react'
import Select from '../../../components/inputs/select'
import useIndex from './useIndex'
import { Input } from '../../../components/inputs/input'
import { FormProvider } from 'react-hook-form'
import { nameField } from '../../../utils/inputValidations'
import Toast from '../../../components/general/toast'
import Card from '../../../components/containers/card'
import Table from '../../../components/containers/table'
import Popover from '../../../components/containers/popover'


export default function HospitalFacilities({hospitalId}) {

    const priceField = nameField("price", "price", "Price *", "Enter price", true, 0, 10)

    const {
        list, 
        loading,
        handlePriceChange,
        handleFacilityChange,
        handleSubmit,
        handleEditClick,
        handleDeleteClick, 
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        facilities,
        methods
    } = useIndex(hospitalId)

    return (
        <Card>
        <FormProvider {...methods}>
            <form
            onSubmit={e => e.preventDefault()}
            noValidate
            autoComplete="off"
            className="container">
                <div className="row">
                    <div className='col-md-3'>
                        <Select name="facilityId" id="facilityId" label="Select facility" list={facilities} valueProperty="id" onChange={handleFacilityChange} />
                    </div>
                    <div className='col-md-3'>
                        <Input name="price" id="price" type="number" {...priceField} />
                    </div>
                    <div className='col-md-3 inline-form-btn-container'>
                        <button className="btn btn-md btn-info waves-effect waves-light pull-right ml-3" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </form>
        </FormProvider>
        <Table data={list} columns={columns}/>
        {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.facility.name}'`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} '${selectedItem.facility.name}'?`}</p>
                </Popover>
                }
        {showMessage && <Toast text={message} className="success" /> }
        </Card>
    )
}