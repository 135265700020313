import axios from "axios";

export async function Initialize() {

  axios.interceptors.request.use((req) => {
    const token = localStorage.getItem("token");
    if (token) {
      req.headers.Authorization = `${token}`;
    }
    return req;
  });

  axios.interceptors.response.use((response) => response, (error) => {
    if (error.response && (error.response.status === 401)) {
      localStorage.clear();
      if (error.request.responseURL.indexOf("/auth/token") < 0) {
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    }
    return error;
  });
}
