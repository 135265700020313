import React from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../utils/inputValidations";
import { Input } from "../../components/inputs/input";
import useItemForm from './useItemForm'
import ProcessLoader from "../../components/general/process-loader";
import FileSelector from "../../components/inputs/file-selector";
import Card from "../../components/containers/card";
import PageTitle from "../../components/general/page-title";
import Layout from "../../components/layout";
import FormHeader from "../../components/general/form-header";
import Address from "../../components/address";


export default function Form({formData, onSave}) {

    const nameInputField = nameField("name", "name", "User name *", "Enter user name", true, 3, 100)
    const passwordField = nameField("registrationNumber", "registrationNumber", "Enter password *", "Enter password", true, 8, 30)
    const emailField = nameField("email", "email", "Email*", "Enter email", true, 3, 100)
    const phoneNumberField = nameField("contactNumber", "contactNumber", "Phone number*", "Enter phone number", true, 10, 10)
    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const {
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message, 
        onFileSelect, 
        uploadInProgress,
        pageTitle,
        breadcrumbItems,
        handleAddressSave
    } = useItemForm(onSave);


    const methods = useForm(
        {
            defaultValues: formData ? {
            id: formData.id,
            name: formData.name,
            description: formData.description,
            imageUrl: formData.imageUrl
            }: {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        console.log(data)
        handleSubmit(data)
    })

    return (
        <Layout>
            <PageTitle title={pageTitle} breadCrumbItems={breadcrumbItems} />
            <div className="row">
                <div className="col-lg-6">
                    <Card>
                        <FormHeader title="General" />
                        <FormProvider {...methods}>
                            <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Input id="name" {...nameInputField} showMessage={showMessage} message={message} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input id="password" {...passwordField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input id="email" {...emailField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input id="phoneNumber" {...phoneNumberField} className="mb-2" />
                                    </div>
                                </div>
                                <Input type="textarea" id="description" {...descriptionInputField} className="mb-2" />
                                <FileSelector className="mb-2" name="attachment" existingFiles={[]} onFileSelect={onFileSelect} label="Select File" showProgress={uploadInProgress}/>
                                <button className="btn btn-md btn-info waves-effect waves-light pull-right" onClick={onSubmit}>Save</button>
                            </form>
                        </FormProvider>

                    </Card>
                </div>

                <div className="col-lg-6">
                    <Address />
                </div>
            </div>
            {apiInProgress && <ProcessLoader />}
                    
        </Layout>
    )
}