import { 
    BASE_URL,
    UPLOAD_URL
 } from "../../../service/ApiUrls";
import { post, get, put, Delete, upload } from "../../../service";

const PATH = 'facility'

const getFacilitiesApiUrl = () => {
    return `${BASE_URL}${PATH}`;
};
  
const getFacilityApiUrl = (id) => {
    return id ? `${BASE_URL}${PATH}/${id}` : `${BASE_URL}${PATH}`;
};

export const getFacilities = async () => {
    const response = await get(getFacilitiesApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const getFacility = async (id) => {
    const response = await get(getFacilityApiUrl(id));
    return response;
};

export const createFacility= async (data) => {
    const response = await post(getFacilityApiUrl(), data);
    return response;
};

export const updateFacility = async (data) => {
    const response = await put(getFacilityApiUrl(), data);
    return response;
};

export const deleteFacility = async (data) => {
    const response = await Delete(getFacilityApiUrl(), data);
    return response;
};

export const uploadFile = async (data) => {
    const response = await upload(UPLOAD_URL, data);
    return response;
};

