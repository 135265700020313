import { 
    BASE_URL
 } from "../../../service/ApiUrls";
import { post, get, put, Delete } from "../../../service";

const getHospitalTypesApiUrl = () => {
    return `${BASE_URL}hospital-type`;
};
  
const getHospitalTypeApiUrl = (id) => {
    return id ? `${BASE_URL}hospital-type/${id}` : `${BASE_URL}hospital-type`;
};

export const getHospitalTypes = async () => {
    const response = await get(getHospitalTypesApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const getHospitalType = async (id) => {
    const response = await get(getHospitalTypeApiUrl(id));
    if(!response) {
        return []
    }
    return response;
};

export const createHospitalType= async (data) => {
    const response = await post(getHospitalTypeApiUrl(), data);
    return response;
};

export const updateHospitalType = async (data) => {
    const response = await put(getHospitalTypeApiUrl(), data);
    return response;
};

export const deleteHospitalType = async (data) => {
    const response = await Delete(getHospitalTypeApiUrl(), data);
    return response;
};