import React from "react";

export default function Loader({className}) {
    return(
        <div className="loader-container">
            <div className="loader">
                <div>
                    <div className={`spinner-border spinner-border-sm m-2 ${className ? className: ''}`} role="status"></div>
                    <br/>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    )
}