import React from "react";
import useMenu from "../../hooks/useMenu";
import MenuItem from "./menu-item";

export default function Menu() {

    const {items} = useMenu() 
    return (
      <div className="app-menu">
        <div className="logo-box">
            <a href="index.html" className="logo-light">
                <img src="assets/images/logo-light.png" alt="logo" className="logo-lg" />
                <img src="assets/images/logo-sm.png" alt="small logo" className="logo-sm" />
            </a>

            <a href="index.html" className="logo-dark">
              <h1 className="logo-text">HOSPY ADMIN</h1>
            </a>
        </div>
        <div className="scrollbar show h-100" data-simplebar="init">
          <div className="simplebar-mask">
            <div className="simplebar-offset">
              <div className="simplebar-content-wrapper">
                <div className="simplebar-content">
                  <ul className="menu">
                    {items.map((item, index) => {
                      return <MenuItem key={`menu_item_${index}`} data={item} />
                    })}
                  </ul>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
