import React from 'react'
import VDiv from './vdiv'
import PagePopupHeader from './page-popup-header'

export default function PagePopup({ showHeader, showBackNavigation, title, subTitle, onBack, children, customHeader, className }) {

    return (
        <VDiv className="page-popup">
            <VDiv className={`popup  ${className ? className : ''}`}>
                {showHeader ? <PagePopupHeader goToPrevPage={false} title={title} subTitle={subTitle} showBackNavigation={showBackNavigation} onBack={onBack} /> : null}
                {customHeader ? customHeader : null}
                <VDiv className="popup-content" >
                    {children}
                </VDiv>
            </VDiv>
        </VDiv>
    )
}