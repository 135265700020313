import React, { useRef } from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../utils/inputValidations";
import { Input } from "../../components/inputs/input";

import ProcessLoader from "../../components/general/process-loader";
import Card from "../../components/containers/card";
import PageTitle from "../../components/general/page-title";
import Layout from "../../components/layout";
import FormHeader from "../../components/general/form-header";
import Select from "../../components/inputs/select";
import ContactNumbers from "./contact-numbers";
import Address from "../../components/address";
import Loader from "../../components/general/loader";
import useItemForm from "./useItemForm";
import Toast from "../../components/general/toast";

export default function Form() {
    const nameInputField = nameField("name", "name", "Provider name", "Enter ambulance provider name", true, 3, 100)
    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const addressRef = useRef();
    const {
        hospitals,
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message, 
        pageTitle,
        breadcrumbItems,
        handleAddressSave,
        formData,
        loading,
        ambulanceId,
        errorMessage,
        showErrorMessage
    } = useItemForm()

    
    const methods = useForm(
        {
            defaultValues: formData ? {
            id: formData.id,
            name: formData.name,
            description: formData.description,
            hospitalId: formData.hospital ? formData.hospital.id : undefined  
            }: {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        console.log(data)
        addressRef.current?.onSubmit()
        handleSubmit(data)
    })

    return (
        <Layout>
            <PageTitle title={pageTitle} breadCrumbItems={breadcrumbItems} showSaveButton onSubmit={onSubmit} />
            {loading  ? <Loader/> :
            
            <div className="row">
                <div className="col-lg-6">
                    <Card>
                        {!loading && <>
                        <FormHeader title="General" />
                        <FormProvider {...methods}>
                            <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Input defaultValue={formData && formData.name} id="name" {...nameInputField} showMessage={showErrorMessage} message={errorMessage} className="mb-2" required />
                                    </div>
                                    <div className="col-md-6">
                                        <Select required={false} defaultValue={formData && formData.hospitalId} id="hospitalId" name="hospitalId"  label="Select hospital"  valueProperty="id" className="mb-2" list={hospitals ? hospitals : []} />
                                    </div>
                                </div>
                                <Input defaultValue={formData && formData.description} type="textarea" id="description" {...descriptionInputField} className="mb-2" />
                            </form>
                        </FormProvider>
                        </>
}

                    </Card>
                    <Address formData={formData && formData.address} onSave={handleAddressSave} ref={addressRef} />
                </div>

                <div className="col-lg-6">
                    <ContactNumbers existingNumbers={formData && formData.contactNumbers ? formData.contactNumbers : []} ambulanceId={ambulanceId} />
                </div>
            </div>
            }
            {/** <div className="p-2 bt-1">
                <div className="col-md-12">
                    <button className="btn btn-md btn-info waves-effect waves-light pull-right" onClick={onSubmit}>Save</button>
                </div>
            </div> **/}
            {apiInProgress && <ProcessLoader />}
            {showMessage && <Toast text={message} className="success" /> }
        </Layout>
    )
}