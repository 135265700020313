import React from 'react'
import Select from '../../../components/inputs/select'
import useIndex from './useIndex'
import { FormProvider } from 'react-hook-form'
import Toast from '../../../components/general/toast'
import Card from '../../../components/containers/card'
import Table from '../../../components/containers/table'
import Popover from '../../../components/containers/popover'
import Loader from '../../../components/general/loader'
import { nameField } from '../../../utils/inputValidations'
import { Input } from '../../../components/inputs/input'


export default function HospitalTimings({hospitalId}) {
    const {
        list, 
        loading,
        handleSubmit,
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        days,
        methods,
        handleCancel,
        handleRowClick
    } = useIndex(hospitalId)

    const startTimeField = nameField("startTime", "startTime", "Start time *", "Select start time", true, 0, 10, "Required", "time")
    const endTimeField = nameField("endTime", "endTime", "End time *", "Select end time", true, 0, 10, "Required", "time")



    return (
        <Card>
        <FormProvider {...methods}>
            <form
            onSubmit={e => e.preventDefault()}
            noValidate
            autoComplete="off"
            className="container">
                <div className="row">
                    <div className='col-md-3'>
                        <Select name="dayId" id="dayId" label="Select day" list={days} valueProperty="id" />
                    </div>
                    <div className='col-md-3'>
                        <Input {...startTimeField}/>
                    </div>
                    <div className='col-md-3'>
                        <Input {...endTimeField}/>
                    </div>
                    <div className='col-md-3 inline-form-btn-container'>
                        <button className="btn btn-md btn-info waves-effect waves-light pull-right ml-3" onClick={handleSubmit}>Save</button>
                        <button className="btn btn-md btn-danger pull-right ml-3" onClick={handleCancel}>Cancel</button>
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-3'></div>
                </div>
            </form>
        </FormProvider>
        {loading ? <Loader /> : <Table data={list} columns={columns} onRowClicked={handleRowClick} />}
        {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.day.name}' - ${selectedItem.startTime} to ${selectedItem.endTime}`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} ''${selectedItem.day.name}' - ${selectedItem.startTime} to ${selectedItem.endTime}'?`}</p>
                </Popover>
                }
        {showMessage && <Toast text={message} className="success" /> }
        </Card>
    )
}