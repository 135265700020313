import React, { useRef } from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../utils/inputValidations";
import { Input } from "../../components/inputs/input";
import useItemForm from './useItemForm'
import ProcessLoader from "../../components/general/process-loader";
import FileSelector from "../../components/inputs/file-selector";
import Card from "../../components/containers/card";
import PageTitle from "../../components/general/page-title";
import Layout from "../../components/layout";
import FormHeader from "../../components/general/form-header";
import Select from "../../components/inputs/select";
import Address from "../../components/address";
import Loader from "../../components/general/loader";
import Toast from "../../components/general/toast";


export default function Form() {

    const fistNameField = nameField("firstName", "firstName", "Doctor first name *", "Enter first name", true, 3, 100)
    const lastNameField = nameField("lastName", "lastName", "Doctor last name *", "Enter last name", false, 0, 100)
    const serviceStartDateField = nameField("serviceStartDate", "serviceStartDate", "Service start date*", "Select date", false, 0, 100)

    const registrationNumberField = nameField("registrationNumber", "registrationNumber", "Medical counsilor register number *", "Enter name", true, 3, 100)
    const emailField = nameField("email", "email", "Email*", "Enter email", true, 3, 100)
    const phoneNumberField = nameField("mobile", "mobile", "Phone number*", "Enter phone number", true, 3, 10)
    const addressRef = useRef();


    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const {
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message, 
        onFileSelect, 
        uploadInProgress,
        pageTitle,
        breadcrumbItems,
        handleAddressSave,
        specializations,
        qualifications,
        loading,
        formData
    } = useItemForm();


    const methods = useForm(
        {
            defaultValues: formData ? {
            id: formData.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            registrationNumber: formData.registrationNumber,
            serviceStartDate: formData.serviceStartDate,
            email: formData.email,
            mobile: formData.mobile,
            description: formData.description,
            imageUrl: formData.imageUrl,
            qualificationId: formData.qualification && formData.qualification.id,
            specializationId: formData.specialization && formData.specialization.id
            }: {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        addressRef.current?.onSubmit()
        handleSubmit(data)
    })

    return (
        <Layout>
            <PageTitle title={pageTitle} breadCrumbItems={breadcrumbItems} />
            {loading  ? <Loader/> :
            <div className="row">
                <div className="col-lg-6">
                    <Card>
                    {!loading && <>
                        <FormHeader title="General" />
                        <FormProvider {...methods}>
                            <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Input defaultValue={formData && formData.firstName} id="firstName" {...fistNameField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input defaultValue={formData && formData.lastName} id="lastName" {...lastNameField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input defaultValue={formData && formData.registrationNumber} id="registrationNumber" {...registrationNumberField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input defaultValue={formData && formData.email} id="email" {...emailField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input defaultValue={formData && formData.mobile} id="mobile" {...phoneNumberField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Input defaultValue={formData && formData.formattedServiceStartDate}  id="serviceStartDate" {...serviceStartDateField} type="date" className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Select className="mb-2" defaultValue={formData && formData.qualification.id} id="qualificationId" name="qualificationId"  label="Select qualification*"  valueProperty="id" list={qualifications ? qualifications : []} />
                                    </div>
                                    <div className="col-md-6">
                                        <Select className="mb-2" defaultValue={formData && formData.specialization.id} id="specializationId" name="specializationId"  label="Select specialization*"  valueProperty="id" list={specializations ? specializations : []} />
                                    </div>
                                    <div className="col-md-12">
                                        <Input defaultValue={formData && formData.description} type="textarea" id="description" {...descriptionInputField} className="mb-2" />
                                    </div>
                                    <div className="col-md-12">
                                        <FileSelector className="mb-2 logo-uploader" name="attachment" existingFiles={formData ? formData.files : []} onFileSelect={onFileSelect} label="Select File" showProgress={uploadInProgress}/>
                                    </div>
                                </div>
                                <button className="btn btn-md btn-info waves-effect waves-light pull-right" onClick={onSubmit}>Save</button>
                            </form>
                        </FormProvider>
                        </>}
                    </Card>
                </div>
                <div className="col-lg-6">
                    <Address formData={formData && formData.address} onSave={handleAddressSave} ref={addressRef}/>
                </div>
            </div>
            }

            {apiInProgress && <ProcessLoader />}
            {showMessage && <Toast text={message} className="success" /> }
        </Layout>
    )
}