import React from "react";
import useLogin from "./useLogin";
import { FormProvider } from 'react-hook-form'

import {
    email_validation,
    password_validation_2,
  } from '../../utils/inputValidations'

import FormError from "../../components/inputs/form-error";
import ProcessLoader from "../../components/general/process-loader";
import { Input } from "../../components/inputs/input";

export default function Login() {

    const { methods, onSubmit, errorMessage, showErrorMessage, onInputChange, apiInProgress } = useLogin()


    return (
        <div className="account-pages">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-4">
                        <div className="card bg-pattern">

                            <div className="card-body p-4">
                                
                                <div className="text-center w-75 m-auto">
                                    <div className="auth-brand">
                                        HOSPY ADMIN
                                    </div>
                                    <p className="text-muted mb-4 mt-3">Enter your email address and password to access admin panel.</p>
                                </div>

                                <FormProvider {...methods}>
                                    <form
                                    onSubmit={e => e.preventDefault()}
                                    onChange={event => onInputChange(event)}
                                    noValidate
                                    autoComplete="off"
                                    className="container">

                            <Input {...email_validation} className="mb-3" />
                            <Input {...password_validation_2} className="mb-3" />
                        <FormError className="error" message={errorMessage} show={showErrorMessage} />

                                        <div className="mb-3">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="checkbox-signin" checked />
                                                <label className="form-check-label" for="checkbox-signin">Remember me</label>
                                            </div>
                                        </div>

                                        <div className="text-center d-grid">
                                            <button className="btn btn-primary" type="submit" onClick={onSubmit}> Log In </button>
                                        </div>
                                    </form>
                                </FormProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {apiInProgress && <ProcessLoader />}
        </div>
        
    )
}