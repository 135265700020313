import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { login } from "./LoginAPI";

import { useForm } from 'react-hook-form'

export default function useLogin() {

    const navigate = useNavigate();
    const methods = useForm()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [apiInProgress, setApiInProgress] = useState(false)


    const onSubmit = methods.handleSubmit(data => {
        doLogin(data)
    })
    
    const onInputChange = (event) => {
        if (showErrorMessage) {
            setShowErrorMessage(false)
        }
    }

    const handleLoginResponse = async (response) => {
        if (response) {
            if(response.status === 200) {
                const data = response.data
                localStorage.setItem("token", data.token);
                localStorage.setItem("firstName", data.firstName);
                localStorage.setItem("lastName", data.lastName);
                localStorage.setItem("userId", data.id);
                localStorage.setItem("email", data.email);
                if (data.token) {
                    navigate(`/dashboard`);
                }
            } else if(response.status === 401) {
                setErrorMessage("Invalid credentials.")
                setShowErrorMessage(true)
            }

        } else {
            setErrorMessage("Something went wrong. Try again later.")
            setShowErrorMessage(true)
        }
    };

    const doLogin = async (data) => {
        setApiInProgress(true)
        localStorage.clear();
        const response = await login(data);
        await handleLoginResponse(response);
        setApiInProgress(false)
    };
    
    return { onSubmit, methods, errorMessage, showErrorMessage, onInputChange, apiInProgress };
}
