import { 
    BASE_URL,
    UPLOAD_URL
 } from "../../service/ApiUrls";
import { post, get, put, Delete, upload } from "../../service";

const PATH = 'doctor'

const SPECIALIZATION_PATH = 'specialization'

const QUALIFICATION_PATH = 'qualification'

const getAllApiUrl = () => {
    return `${BASE_URL}${PATH}`;
};

const getSearchApiUrl = (registrationNumber, stateId) => {
    return `${BASE_URL}${PATH}/${registrationNumber}/state/${stateId}`;
};

const getAllSpecializationApiUrl = () => {
    return `${BASE_URL}${SPECIALIZATION_PATH}`;
};

const getAllQualificationApiUrl = () => {
    return `${BASE_URL}${QUALIFICATION_PATH}`;
};
  
const getApiUrl = (id) => {
    return id ? `${BASE_URL}${PATH}/${id}` : `${BASE_URL}${PATH}`;
};

export const getAll = async () => {
    const response = await get(getAllApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const getItem = async (id) => {
    const response = await get(getApiUrl(id));
    return response;
};

export const searchDoctor = async (registrationNumber, stateId) => {
    const response = await get(getSearchApiUrl(registrationNumber, stateId));
    return response;
};

export const createItem = async (data) => {
    const response = await post(getApiUrl(), data);
    return response;
};

export const updateItem = async (data) => {
    const response = await put(getApiUrl(), data);
    return response;
};

export const deleteItem = async (data) => {
    const response = await Delete(getApiUrl(), data);
    return response;
};

export const uploadFile = async (data) => {
    const response = await upload(UPLOAD_URL, data);
    return response;
};

export const getSpecializations = async () => {
    const response = await get(getAllSpecializationApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const getQualifications = async () => {
    const response = await get(getAllQualificationApiUrl());
    if(!response) {
        return []
    }
    return response;
};

