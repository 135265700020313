import { useEffect, useState } from 'react'
import { createItem, updateItem, getItem, uploadFile } from './rest-service';
import { useNavigate, useParams } from 'react-router-dom';

export default function useItemForm(onSave) {

    const navigate = useNavigate();

    const { id } = useParams();

    const [loading, setLoading] = useState(true)

    const [apiInProgress, setApiInProgress] = useState(false)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [files, setFiles] = useState([])

    const [formData, setFormData] = useState(undefined);

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [hospyPackage, setHospyPackage] = useState();

    const [packageId, setPackageId] = useState(id)

    const pageTitle = "Package"

    const breadcrumbItems = ["Home", "Packages", id ? "edit" :  "create"]


    const onFileSelect = (files) => {
        setFiles(files)
    }

    const fetchItem = async (id) => {
        setLoading(true)
        const response = await getItem(id)

        if(response) {
            setHospyPackage(response)
            if(response.imageUrl) {
                response.files = [{image: response.imageUrl, name:"Hospy"}]
            }else {
                response.files = []
            }
            setFormData(response)
            console.log("********** form data ************")
            console.log(response)
        }
        setLoading(false)
    }

    const handleSubmit = async (data) => {
        setShowErrorMessage(false)
        setApiInProgress(true)
        let payload = {
            id: id, 
            name: data.name, 
            description: data.description, 
            imageUrl: hospyPackage && hospyPackage.imageUrl,
            startingAge: data.startingAge,
            ageUpto: data.ageUpto,
            price: data.price
        }
        if(files && files.length > 0){
            payload.imageUrl = await upload()
        }
        let response;
        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response || !response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            setPackageId(response.data.id)
            displayMessage(`${pageTitle} '${response.data.name}' has been saved successfully.`)
            navigate("/package/"+response.data.id)
        }
        setApiInProgress(false)
    }

    const upload = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            file: file
        }
        let response = await uploadFile(data)
        setUploadInProgress(false)
        if(response) {
            return response.mediaUrl;
        }
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    useEffect(() => {
        if(id) {
            async function fetchItem() {
                setLoading(true)
                const response = await getItem(id)
                if(response) {
                    setHospyPackage(response)
                    if(response.imageUrl) {
                        response.files = [{image: response.imageUrl, name:"Hospy"}]
                    }else {
                        response.files = []
                    }
                    setFormData(response)
                }
                setLoading(false)
            }
            fetchItem()
        } else {
            setLoading(false)
        }
    }, [id])

    return {
        loading,
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message, 
        uploadInProgress, 
        onFileSelect,
        pageTitle,
        breadcrumbItems,
        hospyPackage,
        formData,
        errorMessage,
        showErrorMessage
    }
}