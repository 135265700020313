import { useState } from 'react'
import { createItem, updateItem, uploadFile } from './rest-service';

export default function useItemForm(onSave) {

    const [apiInProgress, setApiInProgress] = useState(false)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const onFileSelect = (files) => {
        setFiles(files)
    }

    const handleSubmit = async (data) => {
        setApiInProgress(true)
        let payload = {id: data.id, name: data.name, description: data.description, imageUrl: data.imageUrl}
        if(files && files.length > 0){
            payload.imageUrl = await upload()
        }
        let response;
        if(payload.id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response.success) {
            setMessage(response.data.errors.message)
            setShowMessage(true)
        }else {
            setMessage("")
            setShowMessage(false)
            if(onSave) {
                onSave(data)
            }
        }
        setApiInProgress(false)
        
    }

    const upload = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            file: file
        }
        let response = await uploadFile(data)
        setUploadInProgress(false)
        if(response) {
            return response.mediaUrl;
        }
    }

    return {handleSubmit, apiInProgress, showMessage, message, uploadInProgress, onFileSelect}
}