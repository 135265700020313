import { LOGIN } from "../../service/ApiUrls";
import { post2 } from "../../service";

export async function login(data) {
  const headers = {
    Authorization: "Basic "+btoa(data.email + ':' + data.password)
  };
  const result = await post2(LOGIN, {}, headers);
  return result
}
