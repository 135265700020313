import React from "react";
import Menu from "./menu";
import Header from "./header";

export default function Layout({ children }) {
    return (
        <div id="wrapper">
                <Menu />
                <div className="content-page">
                    <Header />
                    <div className="content">
                        <div className="container-fluid content-container">
                            {children}
                        </div>
                    </div>

                </div>

        </div>
    )
}