import { 
    BASE_URL
 } from "../../../service/ApiUrls";
import { post, get, put, Delete } from "../../../service";

const PATH = 'qualification'

const getQualificationsApiUrl = () => {
    return `${BASE_URL}${PATH}`;
};
  
const getQualificationApiUrl = (id) => {
    return id ? `${BASE_URL}${PATH}/${id}` : `${BASE_URL}${PATH}`;
};

export const getQualifications = async () => {
    const response = await get(getQualificationsApiUrl());
    if(!response) {
        return []
    }
    return response;
};

export const getQualification = async (id) => {
    const response = await get(getQualificationApiUrl(id));
    return response;
};

export const createQualification= async (data) => {
    const response = await post(getQualificationApiUrl(), data);
    return response;
};

export const updateQualification = async (data) => {
    const response = await put(getQualificationApiUrl(), data);
    return response;
};

export const deleteQualification = async (data) => {
    const response = await Delete(getQualificationApiUrl(), data);
    return response;
};