import React from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../utils/inputValidations";
import { Input } from "../../components/inputs/input";
import useItemForm from './useItemForm'
import ProcessLoader from "../../components/general/process-loader";
import FileSelector from "../../components/inputs/file-selector";
import Card from "../../components/containers/card";
import PageTitle from "../../components/general/page-title";
import Layout from "../../components/layout";
import FormHeader from "../../components/general/form-header";
import PreRequestives from "./pre-requestives";
import Tests from './tests'
import Benefits from './benefits'
import Loader from '../../components/general/loader'
import Toast from "../../components/general/toast";


export default function Form() {

    const nameInputField = nameField("name", "name", "Package name *", "Enter name", true, 3, 100)
    const priceField = nameField("price", "price", "Price *", "Enter price", true, 0, 10)
    const startingAgeField = nameField("startingAge", "startingAge", "Starting age", "Enter starting age", false, 0, 2)
    const ageUptoField = nameField("ageUpto", "ageUpto", "Age upto", "Enter age upto", false, 0, 2)
    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const {
        loading,
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message, 
        onFileSelect, 
        uploadInProgress,
        pageTitle,
        breadcrumbItems,
        formData,
        errorMessage,
        showErrorMessage
    } = useItemForm();

    const methods = useForm(
        {
            defaultValues: formData ? {
            id: formData.id,
            name: formData.name,
            price: formData.price,
            startingAge: formData.startingAge,
            ageUpto: formData.ageUpto,
            description: formData.description,
            imageUrl: formData.imageUrl
            }: {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        handleSubmit(data)
    })

    return (
        <Layout>
            <PageTitle title={pageTitle} breadCrumbItems={breadcrumbItems} />
            {loading ? <Loader /> :<>
            <ul className="nav nav-tabs nav-bordered mb-3" role="tablist">
                <li className="nav-item" role="presentation">
                    <a href="#package-basic" data-bs-toggle="tab" aria-expanded="false" className="nav-link active" aria-selected="false" role="tab" tabIndex="-1">
                        Basic info
                    </a>
                </li>
                <li className="nav-item" role="presentation">
                    <a href="#package-tests" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="0">
                        Tests
                    </a>
                </li>
                <li className="nav-item" role="presentation">
                    <a href="#package-benefits" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="0">
                        Benefits
                    </a>
                </li>
                <li className="nav-item" role="presentation">
                    <a href="#package-pre-requestives" data-bs-toggle="tab" aria-expanded="false" className="nav-link" aria-selected="false" role="tab" tabIndex="0">
                        Pre requestives
                    </a>
                </li>
            </ul>
                <div className="tab-content">

                    <div id="package-basic" className="tab-pane active">
                        <Card>
                            <FormHeader title="General" />
                            <FormProvider {...methods}>
                                <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input id="name" defaultValue={formData && formData.name} {...nameInputField} className="mb-2" message={errorMessage} showMessage={showErrorMessage} />
                                        </div>
                                        <div className="col-md-6">
                                            <Input id="price" defaultValue={formData && formData.price} {...priceField} type="number" className="mb-2" />
                                        </div>
                                        <div className="col-md-6">
                                            <Input id="startingAge" defaultValue={formData && formData.startingAge} {...startingAgeField} type="number" className="mb-2" />
                                        </div>
                                        <div className="col-md-6">
                                            <Input id="ageUpto" defaultValue={formData && formData.ageUpto} {...ageUptoField} type="number" className="mb-2" />
                                        </div>
                                        <div className="col-md-12">
                                            <Input type="textarea" id="description" defaultValue={formData && formData.description} {...descriptionInputField} className="mb-2" />
                                        </div>
                                        <div className="col-md-12">
                                            <FileSelector className="mb-2 logo-uploader" name="attachment" existingFiles={formData ? formData.files : []} onFileSelect={onFileSelect} label="Select File" showProgress={uploadInProgress}/>
                                        </div>
                                        <div className="col-md-12 p-2 bt-1">
                                            <button className="btn btn-md btn-info waves-effect waves-light pull-right" onClick={onSubmit}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            </FormProvider>
                        </Card>
                    </div>

                    <div id="package-tests" className="tab-pane">
                        <Tests existingItems={[]} />
                    </div>
                    <div id="package-benefits" className="tab-pane">
                        <Benefits existingItems={[]} />
                    </div>
                    <div id="package-pre-requestives" className="tab-pane">
                        <PreRequestives existingItems={[]} />
                    </div>
                </div>
                </>}
            {apiInProgress && <ProcessLoader />}
            {showMessage && <Toast text={message} className="success" /> }
                    
        </Layout>
    )
}