import React from "react"
import { useForm, FormProvider } from 'react-hook-form'
import Card from "../../components/containers/card";
import FormHeader from "../../components/general/form-header";
import useContactNumbers from "./useContactNumbers";
import Table from "../../components/containers/table";
import Popover from "../../components/containers/popover";
import Toast from "../../components/general/toast";
import FormError from "../../components/inputs/form-error";
import { nameField } from "../../utils/inputValidations";
import { GroupInput } from "../../components/inputs/group-input";

export default function ContactNumbers({ambulanceId, existingNumbers}) {

    const phoneInputField = nameField("contactNumber", "contactNumber", "Contact number*", "Enter contact number", true, 10, 10)


    const {
        apiInProgress, 
        showMessage, 
        message,
        handleInputChange,
        handleAddClick,
        numbers,
        columns,
        showConfirmation,
        selectedItem ,
        onDeleteCancel,
        onDeleteConfirm,
        errorMessage,
        showErrorMessage

    } = useContactNumbers(ambulanceId, existingNumbers)

    const methods = useForm(
        {
            defaultValues: {
                contactNumber: ""
            }
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        console.log(data)
        handleAddClick(data)
    })

    return(
        <Card>
            <FormHeader title="Contact Numbers" />
                <FormProvider {...methods}>
                    <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                        <div className="row">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="contactNumber">Contact number*</label>
                                <div className="input-group">
                                    <GroupInput  id="contactNumber" {...phoneInputField} name="contactNumber" onChange={event => handleInputChange(event)} type="text" placeholder="Enter contact number" aria-label="Contact Number" />
                                    <button onClick={onSubmit} className="btn input-group-text btn-info waves-effect waves-light" type="button">ADD</button>
                                </div>
                                <FormError className="error" message={errorMessage} show={showErrorMessage} />
                            </div>
                        </div>
                    </form>
                </FormProvider>
                <div className="row">
                    {!apiInProgress &&
                        <Table data={numbers} className="dataTable" columns={columns} pagination/>
                    }
                </div>
                {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.contactNumber}'`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} '${selectedItem.contactNumber}'?`}</p>
                </Popover>
            }
            
            {showMessage && <Toast text={message} className="success" /> }
        </Card>
    )




}