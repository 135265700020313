import React from 'react'
import Select from '../../../components/inputs/select'
import useIndex from './useIndex'
import { FormProvider } from 'react-hook-form'
import Toast from '../../../components/general/toast'
import Card from '../../../components/containers/card'
import Table from '../../../components/containers/table'
import Popover from '../../../components/containers/popover'
import Loader from '../../../components/general/loader'
import Form from './form'


export default function HospitalDoctors({hospitalId}) {
    const {
        list, 
        loading,
        handleSubmit,
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        doctors,
        methods,
        showForm,
        handleRowClick,
        handleCancelClick
    } = useIndex(hospitalId)

    return (
        <Card>
        <FormProvider {...methods}>
            <form
            onSubmit={e => e.preventDefault()}
            noValidate
            autoComplete="off"
            className="container">
                <div className="row">
                    <div className='col-md-3'>
                        <Select name="doctorId" id="doctorId" label="Select doctor" list={doctors} valueProperty="id" />
                    </div>
                    <div className='col-md-9 inline-form-btn-container'>
                        <button className="btn btn-md btn-info waves-effect waves-light pull-right ml-3" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </form>
        </FormProvider>
        {loading ? <Loader /> : <Table data={list} columns={columns} pagination onRowClicked={handleRowClick}/>}
        {showConfirmation && 
                <Popover 
                subTitle={`Confirm ${selectedItem.deleted ? 'de-activating' : 'activating'} '${selectedItem.doctor.firstName} ${selectedItem.doctor.lastName}'`} 
                showActions 
                saveLabel="Yes" 
                onCancel={onDeleteCancel} 
                onClose={onDeleteCancel}
                onSave={onDeleteConfirm}  
                >
                <p className="mt-1">{`Are you sure that you want to ${selectedItem.deleted ? 'de-activate' : 'activate'} '${selectedItem.doctor.firstName} ${selectedItem.doctor.lastName}'?`}</p>
                </Popover>
                }
        {selectedItem && showForm && <Form doctor={selectedItem.doctor} hospitalId={hospitalId} onCancel={handleCancelClick} />}
        {showMessage && <Toast text={message} className="success" /> }
        </Card>
    )
}