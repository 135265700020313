import React from "react"

export default function NoDataMessage({showAddButton, onAdd}) {

    return(
        <div className="misc-wrapper">
            <div className="misc-wrapper-content">
                <h2 className="mb-2 mx-2">No records found!</h2>
                <p className="mb-4 mx-2">Looks like you haven't added any records or zero records found.{showAddButton && <span> Click the 'Add' button to add records.</span>}</p>
                {
                    showAddButton && 
                    <button onClick={() => onAdd()} type="button" className="btn btn-info waves-effect waves-light mb-2 me-2"><i className="mdi mdi-basket me-1"></i> Add </button>

                }
                <div className="mt-4">
                    <img
                        src="../assets/img/illustrations/girl-doing-yoga-light.png"
                        alt="girl-doing-yoga-light"
                        width="500"
                        className="img-fluid"
                        data-app-dark-img="illustrations/girl-doing-yoga-dark.png"
                        data-app-light-img="illustrations/girl-doing-yoga-light.png"
                    />
                </div>
            </div>
        </div>
    )

}