import React from 'react'
import EditButton from './edit-button'
import ToggleButton from './toggle-button'

export default function ActionButtons({onDelete, onEdit, row}) {
    return(
        <div className='flex'>
            <EditButton onClick={onEdit} />
            <ToggleButton selected={row.deleted} onChange={onDelete} data={row} id={"deleted_"+row.id} />
        </div>
    )
}