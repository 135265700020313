import { useEffect, useState } from 'react'
import { createItem, updateItem, getItem, uploadFile, getSpecializations, getQualifications } from './rest-service';
import { useNavigate, useParams } from 'react-router-dom';
import { getFormattedDate } from '../../utils/formatters';

export default function useItemForm() {

    const navigate = useNavigate();

    const { id, registrationNumber, stateId } = useParams();

    const [apiInProgress, setApiInProgress] = useState(false)

    const [loading, setLoading] = useState(true)

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [specializations, setSpecializations] = useState([])

    const [qualifications, setQualifications] = useState([])

    const [formData, setFormData] = useState(
        registrationNumber ? 
        {"registrationNumber": registrationNumber, "stateId": stateId, qualification: {}, specialization: {}, address: {state: {id: Number(stateId)}, country: {}, city: {}, district: {}}, files: []} 
        : undefined);

    const [doctor, setDoctor] = useState(id)

    const pageTitle = "Doctor"

    const breadcrumbItems = ["Home", "Doctors", id ? "edit" :  "create"]

    let data = undefined


    const onFileSelect = (files) => {
        setFiles(files)
    }

    const handleSubmit = async (payload) => {
        data = payload
    }

    const fetchItem = async (id) => {
        setLoading(true)
        const response = await getItem(id)

        if(response) {
            setDoctor(response)
            if(response.serviceStartDate) {
                response.formattedServiceStartDate = getFormattedDate(response.serviceStartDate)
            }
            if(response.imageUrl) {
                response.files = [{image: response.imageUrl, name:"Hospy"}]
            }else {
                response.files = []
            }
            setFormData(response)
            console.log("********** form data ************")
            console.log(response)
        }
        setLoading(false)
    }

    const upload = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            file: file
        }
        let response = await uploadFile(data)
        setUploadInProgress(false)
        if(response) {
            return response.mediaUrl;
        }
    }

    const handleAddressSave = async (address) => {
        setApiInProgress(true)

        let payload = {
            id: id, 
            firstName: data.firstName, 
            lastName: data.lastName,
            registrationNumber: data.registrationNumber,
            serviceStartDate: data.serviceStartDate,
            email: data.email,
            mobile: data.mobile,
            description: data.description, 
            imageUrl: doctor && doctor.imageUrl,
            specialization: {id: data.specializationId},
            qualification: {id: data.qualificationId},
            address: {id: address.id},
            state: {id: address.state.id}
        }
        if(files && files.length > 0){
            payload.imageUrl = await upload()
        }
        
        let response;
        if(id) {
            response = await updateItem(payload)
        }else {
            response = await createItem(payload)
        }
        if(!response || !response.success) {
            setErrorMessage(response.data.errors.message)
            setShowErrorMessage(true)
        }else {
            displayMessage(`${pageTitle} '${response.data.firstName}' has been saved successfully.`)
            navigate("/doctors")
        }
        setApiInProgress(false)

        

    }

    const fetchSpecializations = async () => {
        const response = await getSpecializations()
        setSpecializations(response)
    }

    const fetchQualifications = async () => {
        const response = await getQualifications()
        setQualifications(response)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    useEffect(() => {
        fetchSpecializations()
        fetchQualifications()
        if(id) {
            fetchItem(id)
        } else {
            setLoading(false)
        }
    }, [id])

    return {
        handleSubmit, 
        apiInProgress, 
        showMessage, 
        message, 
        errorMessage,
        showErrorMessage,
        uploadInProgress, 
        onFileSelect,
        pageTitle,
        breadcrumbItems,
        handleAddressSave,
        specializations,
        qualifications,
        formData,
        loading
    }
}