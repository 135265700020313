import React from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField, descriptionField } from "../../../utils/inputValidations";
import { Input } from "../../../components/inputs/input";
import PagePopup from "../../../components/containers/page-popup";
import useItemForm from './useItemForm'
import ProcessLoader from "../../../components/general/process-loader";
import FormActionButtons from "../../../components/inputs/form-action-buttons";
import FileSelector from "../../../components/inputs/file-selector";


export default function Form({formData, onCancel, onSave}) {

    const itemName = "Room"
    const nameInputField = nameField("name", "name", itemName, "Enter name", true, 3, 100)
    const descriptionInputField = descriptionField("description", "description", "Description", "Enter description", false, 255)
    const {handleSubmit, apiInProgress, showMessage, message, onFileSelect, uploadInProgress} = useItemForm(onSave);


    const methods = useForm(
        {
            defaultValues: formData ? {
            id: formData.id,
            name: formData.name,
            description: formData.description,
            imageUrl: formData.imageUrl
            }: {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        console.log(data)
        handleSubmit(data)
    })

    return (
        <PagePopup title="Room" onBack={onCancel} showHeader showBackNavigation>
            <FormProvider {...methods}>
                <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                    <Input id="name" {...nameInputField} showMessage={showMessage} message={message} className="mb-3" required />
                    <Input type="textarea" id="name" {...descriptionInputField} className="mb-3" />
                    <FileSelector name="attachment" existingFiles={formData.files ? formData.files : []} onFileSelect={onFileSelect} label="Upload File" showProgress={uploadInProgress}/>
                    <FormActionButtons onSubmit={onSubmit} onCancel={onCancel} />
                    {apiInProgress && <ProcessLoader />}
                </form>
            </FormProvider>
        </PagePopup>
    )
}