import React, { forwardRef, useImperativeHandle } from "react";
import Card from "../containers/card";
import FormHeader from "../general/form-header";
import Select from "../inputs/select";
import useAddress from "./useAddress";
import { useForm, FormProvider } from 'react-hook-form'
import { Input } from "../inputs/input";
import { nameField } from "../../utils/inputValidations";

const Address = forwardRef((props, ref) => {

    const addressInputField = nameField("addressLine1", "addressLine1", "Address *", "Enter address", true, 3, 255)

    const {
        handleSubmit, 
        countries,
        states,
        districts,
        cities,
        handleCountryChange,
        handleStateChange,
        handleDistrictChange,
        handleCityChange
    } = useAddress(props.onSave, props.formData)

    console.log(props.formData)

    const methods = useForm(
        {
            defaultValues: props.formData ? {
            id: props.formData.id,
            addressLine1: props.formData.addressLine1,
            countryId: props.formData.country.id,
            stateId: props.formData.state.id,
            districtId: props.formData.district.id,
            cityId: props.formData.city.id
            }: {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        handleSubmit(data)
    })

    useImperativeHandle(ref, () => {
        return {
            onSubmit
        }
    })

    return (

        <Card>
            <FormHeader title="Address" />
            <FormProvider {...methods}>
                <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <Input id="name" {...addressInputField} />
                        </div>   
                        <div className="col-md-6 mb-2">
                            <Select defaultValue={props.formData && props.formData.country.id} id="countryId" onChange={handleCountryChange} list={countries} name="countryId" label="Select country*" valueProperty="id"/>
                        </div>
                        <div className="col-md-6 mb-2">
                            <Select defaultValue={props.formData && props.formData.state.id} id="stateId" onChange={handleStateChange} list={states} name="stateId" label="Select state*" valueProperty="id"/>
                        </div>
                        <div className="col-md-6 mb-2">
                            <Select defaultValue={props.formData && props.formData.district ? props.formData.district.id : undefined} id="districtId" onChange={handleDistrictChange} list={districts} name="districtId" label="Select district*" valueProperty="id"/>
                        </div>
                        <div className="col-md-6 mb-2">
                            <Select defaultValue={props.formData && props.formData.city.id} id="cityId" onChange={handleCityChange} list={cities} name="cityId" label="Select city*" valueProperty="id"/>
                        </div>
                    </div>    
                </form>
            </FormProvider>
        </Card>
    )
})

export default Address;