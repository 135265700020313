import { useState, useEffect } from "react"
import { getAll } from "./rest-service"


export default function useIndex() {

    const [list, setList] = useState([])

    const [loading, setLoading] = useState(true)

    const pageTitle = "Dashboard"

    const fetchAll = async () => {
        setLoading(true)
        const response = await getAll()
        setList(response)
        setLoading(false)
    }

    const breadcrumbItems = ["Home", pageTitle]

    useEffect(() => {
        fetchAll()
    }, [])

    return {
        list, 
        loading, 
        breadcrumbItems,
        pageTitle
    }

}