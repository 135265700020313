import { useState, useEffect } from "react"
import { deleteItem, getProceduresByLevel, getAll, saveItem, getAllLevels } from "./rest-service"
import ActionButtons from "../../../components/inputs/action-buttons"
import Avatar from "../../../components/general/avatar"
import { useForm } from "react-hook-form"

export default function useIndex(hospitalId) {

    const methods = useForm()

    const [apiInProgress, setApiInProgress] = useState(false)

    const [list, setList] = useState([])

    const [procedures, setprocedures] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [loading, setLoading] = useState(true)

    const [showForm, setShowForm] = useState(false)

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const [levels, setLevels] = useState([])

    const itemName = "procedure"

    const [selectedLevel, setSelectedLevel] = useState()

        const handleSubmit = methods.handleSubmit(data => {
            handleAddClick(data)
        })

        const handleAddClick = async (data) => {
            console.log(data)
            if(!hospitalId) {
                setErrorMessage("Cannot save Packages before saving basic info")
                setShowErrorMessage(true)
                return
            }else {
                setShowErrorMessage(false)
            }
            setApiInProgress(true)
            let payload = {hospital: {id: hospitalId}, procedure: {id: data.procedureId}, price: data.price}
            console.log(payload)
            let response = await saveItem(payload)
            if(!response.success) {
                setErrorMessage(response.data.errors.message)
                setShowErrorMessage(true)
            }else {
                setErrorMessage("")
                setShowErrorMessage(false)
                displayMessage("Hospital Package has been saved successfully.")
            }
            fetchHospitalproceduresByLevel(selectedLevel.id)
            setApiInProgress(false)
        }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
        fetchproceduresByLevel(selectedLevel)
    }

    const onDeleteConfirm = async () => {
        await deleteItem({id: selectedItem.id, deleted: selectedItem.deleted})
        fetchproceduresByLevel(selectedLevel)
        setShowConfirmation(false)
        displayMessage(`${itemName} '${selectedItem.procedure.name}' has been updated successfully.`)
    }

    const fetchLevels = async () => {
        setLoading(true)
        const response = await getAllLevels()
        setLevels(response)
        if(response && response.length > 0) {
            setSelectedLevel(response[0])
            fetchproceduresByLevel(response[0])
        }
    }

    const fetchproceduresByLevel = async (level) => {
        methods.reset()
        setLoading(true)
        const response = await getProceduresByLevel(level.id)
        setprocedures(response)
        await fetchHospitalproceduresByLevel(level.id)
        setLoading(false)
    }

    const fetchHospitalproceduresByLevel = async (levelId) => {
        const response = await getAll(hospitalId, levelId)
        setList(response)
    }

    const handleTabClick = (level) => {
        setLoading(true)
        setSelectedLevel(level)
        fetchproceduresByLevel(level)
    }

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) =><span className={`${row.deleted ? 'text-danger' : ''}`}> {index + 1}</span>,
            width: "80px",
            center: "true",
          },
          {
            name: 'Name',
            selector: row => row.procedure.name,
            sortable: true,
            cell: row => <div className={`avatar-container ${row.deleted ? 'text-danger' : ''}`} > <Avatar image={row.procedure.imageUrl} name={row.procedure.name} /> <strong>{row.procedure.name}</strong></div>,
        },
        {
            name: 'Description',
            selector: row =><span className={`${row.deleted ? 'text-danger' : ''}`}> {row.procedure.description}</span> ,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row =><span className={`${row.deleted ? 'text-danger' : ''}`}> {row.price}</span> ,
            sortable: true,
        },
        {
            name: "Action",
            selector: row => <ActionButtons row={row} onEdit={(event) => handleEditClick(event,row)} onDelete={(event, row) => handleDeleteClick(event,row)} />,
            right:"true"
        },
    ];

    useEffect(() => {
        fetchLevels()
    }, [])

    return {
        showForm, 
        list, 
        formData, 
        handleSubmit, 
        loading, 
        handleAddClick, 
        handleCancelClick,
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        columns,
        levels,
        handleTabClick,
        selectedLevel,
        methods,
        apiInProgress,
        showErrorMessage,
        errorMessage,
        procedures
    }

}