import React from "react";
import { useForm, FormProvider } from 'react-hook-form'
import { nameField } from "../../utils/inputValidations";
import { Input } from "../../components/inputs/input";
import useSearchForm from './useSearchForm'
import ProcessLoader from "../../components/general/process-loader";
import Card from "../../components/containers/card";
import PageTitle from "../../components/general/page-title";
import Layout from "../../components/layout";
import FormHeader from "../../components/general/form-header";
import Select from "../../components/inputs/select";


export default function DoctorSearchForm() {

    const registrationNumberField = nameField("registrationNumber", "registrationNumber", "Medical counsilor register number *", "Enter registration number", true, 3, 100)

    const {
        handleSubmit, 
        apiInProgress, 
        pageTitle,
        breadcrumbItems,
        states
    } = useSearchForm();


    const methods = useForm(
        {
            defaultValues: {
                stateId: 1,
                registrationNumber: ""
            }
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        handleSubmit(data)
    })

    return (
        <Layout>
            <PageTitle title={pageTitle} breadCrumbItems={breadcrumbItems} />
            <div className="row">
                <div className="col-lg-12">
                    <Card>
                        <FormHeader title="Search Doctor" />
                        <FormProvider {...methods}>
                            <form  onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Input id="registrationNumber" {...registrationNumberField} className="mb-2" />
                                    </div>
                                    <div className="col-md-6">
                                        <Select className="mb-2" id="stateId" name="stateId"  label="Select state*"  valueProperty="id" list={states ? states : []} />
                                    </div>
                                </div>
                                <button className="btn btn-md btn-info waves-effect waves-light pull-right" onClick={onSubmit}>Search</button>
                            </form>
                        </FormProvider>
                    </Card>
                </div>
            </div>
            {apiInProgress && <ProcessLoader />}
        </Layout>
    )
}