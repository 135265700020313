const HOST = process.env.REACT_APP_API_HOST

export const BASE_AUTH_URL = `${HOST}`;

const BASE_UPLOAD_URL = `${HOST}hospy-service/v1/admin/file-upload`;

export const BASE_URL = `${HOST}hospy-service/v1/admin/`;

export const LOGIN = `${BASE_AUTH_URL}hospy-service/v1/auth/token`;

export const UPLOAD_URL = `${BASE_UPLOAD_URL}`;

export const getUploadApiUrl = () => {
  return BASE_UPLOAD_URL;
};

export const getCountriesApiUrl = () => {
  return `${BASE_URL}country`;
}

export const getStatesApiUrl = (id) => {
  
  return id ? `${BASE_URL}state/country/${id}` : `${BASE_URL}state`;
}

export const getDistrictsApiUrl = (id) => {
  return `${BASE_URL}district/state/${id}`;
}

export const getCitiesApiUrl = (id) => {
  return `${BASE_URL}city/district/${id}`;
}
